.ticket-message-box {
    text-align: left;
    min-width: 150px;
    max-width: 60%;
    min-height: 60px;
    padding: 5px;
    border-radius: 7px;
    margin-top: 5px;
}

.support-ticket {
    background: #E4E4E4!important;
    margin-right: 15px;
}

.user-ticket {
    background: #E4E4F5!important;
    margin-left: 15px;
}

.ticket-page-title {
    text-align: center;
    margin-bottom: 25px!important;
    font-size: 20px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin-top: 60px!important;
}

.ticket-message-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    display: flex;
    flex-direction: column;
}

.ticket-message-username {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 14px!important;
}

.user-ticket-container {
    display: flex;
    justify-content: left;
}

.support-ticket-container {
    display: flex;
    justify-content: right;
}

.ticket-message-img {
    width: 200px;
    padding-top: 5px;
}

.close-ticket-btn {
    position: fixed!important;
    background: #498EDF!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin-left: 15px!important;
    color: white!important;
    height: 45px;
}

.file-type-ticket {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
}

@media (max-width: 600px) {
    .support-ticket {
        max-width: 85%;
        margin-right: 10px;
    }
    
    .user-ticket {
        max-width: 85%;
        margin-left: 10px;
    }
}
