.footer {
  width: 100%;
  padding: 60px 0;
  background: #000000;
}

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.footer__wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.footer__left {
  width: 131px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__logo-wrap {
  width: 112px;
  padding-top: 10px;
}

.footer__logo {
  width: 100%;
}

.footer__copyright {
  width: 170px;
  margin: 0;
  color: #ffffff;
  opacity: 0.6;
}

.footer__copyright-item {
  display: inline-block;
}

.footer__nav {
  width: 100%;
  display: flex;
  justify-content: center;
}

.nav__list--footer {
  gap: 40px;
}

.nav__link--footer {
  width: 100%;
  justify-content: center;
  color: #FFFFFF;
  padding: 5px 0;
}

.nav__link--footer:focus,
.nav__link--footer:hover {
  color: #FFFFFF;
}

.nav__link--footer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  transform: scaleX(0);
  transition: all 0.5s;
}

.nav__link:focus:before,
.nav__link:hover:before {
  transform: scaleX(1);
}

.footer__buttons {
  display: flex;
  gap: 15px;
}

.footer__button {
  padding: 12px 40px;
  border-radius: 10px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-size: 15px;
  line-height: 120%;
}

.footer__button--login {
  min-width: 111px;
  font-weight: 400;
  color: #000000;
  background: #FFFFFF;
}

.footer__button--registration {
  min-width: 166px;
  font-weight: 600;
  color: #FFFFFF;
  background: var(--main-color);
}

@media (max-width: 1140px) {
  .nav__list--footer {
    gap: 26px;
  }
}

@media (max-width: 1100px) {
  .footer__container {
    gap: 25px;
  }

  .nav__list--footer {
    gap: 24px;
  }

  .footer__buttons {
    flex-direction: column;
  }
}

@media (max-width: 940px) {
  .footer__container {
    gap: 10px;
  }

  .nav__list--footer {
    flex-direction: column;
    width: unset;
    padding: 0;
    gap: 8px;
  }

  .nav__link--footer {
    font-size: 18px;
  }
}

@media (max-width: 560px) {
  .footer__wrap {
    flex-direction: column;
  }

  .footer__wrap--top {
    flex-wrap: wrap;
    gap: 20px;
    height: 270px;
  }

  .footer__left {
    order: 1;
    padding-top: 5px;
  }

  .footer__buttons {
    order: 2;
  }

  .footer__nav {
    order: 3;
    width: unset;
  }

  .nav__list--footer {
    gap: 10px;
  }

  .nav__link--footer {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .footer__buttons {
    width: 130px;
  }

  .footer__button--login,
  .footer__button--registration {
    min-width: unset;
  }

  .footer__button {
    padding: 12px 20px;
  }
}