.check-for-one-ruble {
  padding: 60px 0 100px;
  background: #FFFFFF;
}

.check-for-one-ruble__container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.check-for-one-ruble__left {
  width: 637px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 30px;

  background: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.check-for-one-ruble__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.check-for-one-ruble__title {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.check-for-one-ruble__text {
  width: 100%;
  margin: 0;
}

@media (max-width: 1100px) {
  .check-for-one-ruble {
    padding: 46px 0;
  }

  .check-for-one-ruble__container {
    gap: 14px;
  }

  .check-for-one-ruble__left {
    width: 750px;
    padding: 30px;
  }
}

@media (max-width: 880px) {
  .check-for-one-ruble__container {
    flex-direction: column;
  }

  .check-for-one-ruble__left {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .check-for-one-ruble__left {
    padding: 20px;
  }
}