.ticket-system-btn-container {
    width: 80vw;
    margin: 0 auto!important;
    margin-bottom: 10px!important;
}

.create-ticket-btn {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    background: #498EDF!important;
    color: white!important;
    margin: 0 auto!important;
    text-align: center;
}

.ticket-system-title {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    text-align: center;
    font-size: 34px!important;
    margin-top: 15px!important;
    margin-bottom: 15px!important;
}

@media (max-width: 600px) {
    .ticket-system-btn-container {
        width: 75vw;
    }
}