.complete-database {
  padding: 60px 0;
  background: #FFFFFF;
}

.section-heading--complete-database {
  margin-bottom: 40px;
}

.complete-database__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 60px;
  row-gap: 40px;
}

@media (max-width: 1100px) {
  .complete-database {
    padding: 46px 0;
  }

  .section-heading--complete-database {
    margin-bottom: 24px;
  }

  .complete-database__list {
    column-gap: 34px;
  }

  .complete-database__img {
    width: 120px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .complete-database__list {
    row-gap: 30px;
    column-gap: 20px;
    justify-content: space-between;
  }

  .complete-database__img {
    width: 100px;
  }
}
