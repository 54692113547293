.header {
  width: 100%;
  position: relative;
  z-index: 1;
}

.header__container {
  background: rgba(255, 255, 255, 0.7);
  border: 0.9px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.header__wrap {
  padding: 15px 15px 15px 40px;
  display: flex;
  align-items: center;
  gap: 50px;
}

.header__logo-link {
  flex-shrink: 0;
}

.main-nav-toggle {
  display: none;
}

.burger-overlay {
  display: none;
}

.nav__list {
  display: flex;
  gap: 30px;
}

.nav__item {}

.nav__link {
  position: relative;
  display: block;
  padding: 5px 0;
  text-decoration: none;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 140%;
  color: #000000;
}

.nav__link:focus,
.nav__link:hover {
  outline: none;
  color: var(--main-color);
}

.nav__link:active {
  opacity: .7;
}

.nav__link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 76, 219, 0.5);
  transform: scaleX(0);
  transition: all 0.5s;
}

.nav__link:focus:before,
.nav__link:hover:before {
  transform: scaleX(1);
}

.header__buttons {
  margin-left: auto;
  display: flex;
  gap: 15px;
}

.header__button {
  padding: 12px 40px;
  border-radius: 10px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-size: 15px;
  line-height: 120%;
}

.header__button--login {
  min-width: 111px;
  font-weight: 400;
  color: var(--main-color);
  background: #FFFFFF;
  border: 1px solid var(--main-color);
}

.header__button--registration {
  width: 166px;
  font-weight: 600;
  color: #FFFFFF;
  background: var(--main-color);
}

.user-block {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 25px;
}

.user-block__logout-btn {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: rgba(0, 0, 0, 0.3);
}

.user-block__profile-btn {
  width: 30px;
  height: 30px;
}

.user-block__profile-icon path {
  transition: all 0.3s;
}

.user-block__profile-btn:focus .user-block__profile-icon path,
.user-block__profile-btn:hover .user-block__profile-icon path {
  fill: var(--main-color);
  fill-opacity: 1;
}

.user-block__profile-btn:active .user-block__profile-icon path {
  fill-opacity: 0.5;
}

.user-block__drop {
  position: relative;
  width: 30px;
  height: 30px;
}

.drop-down__grid {
  width: 171px;
  position: absolute;
  top: 60px;
  left: -108px;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition-duration: 300ms;
}

.drop-down__grid--open {
  grid-template-rows: 1fr;
}

.drop-down__content {
  overflow: hidden;
  height: 100%;
}

.nav__list--profile {
  overflow: hidden;
  margin: 0 20px 20px;
  padding: 15px 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  background: rgba(255, 255, 255);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

@media (max-width: 1240px) {
  .header__wrap {
    padding: 15px;
    gap: 36px;
  }

  .nav__list {
    gap: 26px;
  }

  .nav__list--profile {
    gap: 5px;
  }
}

@media (max-width: 1140px) {
  .nav__list {
    gap: 20px;
  }

  .nav__list--profile {
    gap: 5px;
  }

  .header__buttons {
    gap: 10px;
  }

  .header__button {
    padding: 12px 20px;
  }

  .header__button--login {
    min-width: unset;
    width: 96px;
  }

  .header__button--registration {
    width: 130px;
  }
}

@media (max-width: 1100px) {
  .header__container {
    width: calc(100% - 2 * 20px);
  }

  .header__wrap {
    padding: 15px 0;
    gap: 22px;
  }

  .header__logo {
    width: 100px;
    height: auto;
  }

  .nav__list {
    gap: 18px;
  }

  .nav__list--profile {
    gap: 5px;
  }

  .header__button--login {
    width: 80px;
  }

  .header__button--registration {
    width: 120px;
  }
}

@media (max-width: 940px) {
  .header__container {
    padding: 0 14px;
  }

  .header__wrap {
    padding: 10px 0;
  }

  .header__logo {
    width: 120px;
    height: auto;
  }

  .header__buttons,
  .user-block {
    margin-right: 100px;
  }

  .main-nav {
    position: absolute;
    height: 100%;
    z-index: 1000;
    top: 0;
    right: 0;
    max-width: 370px;
    width: 100%;
    padding-top: 34px;
    margin: 0px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
  }

  .main-nav--closed {
    position: fixed;
    right: -500px;
    transform: translateX(100%);
    transition: all .7s ease-out;
  }

  .main-nav--opened {
    position: fixed;
    right: -22px;
    transform: translateX(0%);
    transition: all .7s ease-out;
  }

  .burger {
    position: relative;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
  }

  .burger::before,
  .burger::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--main-color);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }

  .burger::before {
    top: 0;
  }

  .burger::after {
    top: calc(100% - 2px);
  }

  .burger__line {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: var(--main-color);
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
  }

  .burger--active::before {
    top: 50%;
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }

  .burger--active::after {
    top: 50%;
    transform: rotate(-45deg);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }

  .burger--active .burger__line {
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }

  .main-nav-toggle {
    position: absolute;
    width: 46px;
    height: 26px;
    top: 50%;
    transform: translateY(-50%);
    right: 36px;
    display: block;
    padding: 0;
    background-color: transparent;
    z-index: 3000;
  }

  .burger-overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    animation: overlay-in 1s linear forwards;
  }

  .burger-overlay--show {
    display: block;
  }

  .no-scroll {
    overflow: hidden;
  }


  @keyframes overlay-in {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .header__nav {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: unset;
  }

  .nav__list {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    /* margin: 0px auto; */
    padding: 30px 0;
    gap: 6px;
  }

  .nav__list--profile { 
    width: 85%;  
    margin: 0 20px 20px;
    padding: 15px 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    overflow: hidden;
  }

  .nav__item {
    display: flex;
    justify-content: center;
  }

  .nav__link {
    display: block;
    padding: 10px;
    font-size: 20px;
    text-align: center;
  }

  .nav__link--profile {
    font-size: 16px;
    padding: 5px 0;
  }
}

@media (max-width: 660px) {
  .header__buttons {
    margin-right: 80px;
  }

  .header__button {
    padding: 10px 20px;
  }

  .user-block {
    gap: 14px;
    margin-right: 80px;
  }

  .main-nav-toggle {
    width: 40px;
    height: 22px;
  }
}

@media (max-width: 560px) {
  .user-block__logout-btn span {
    display: none;
  }

  .header__buttons {
    margin-right: 60px;
  }

  .header__button {
    padding: 10px;
  }

  .header__button--login {
    width: 70px;
  }
}

@media (max-width: 480px) {
  .header__logo {
    width: 100px;
  }

  .main-nav-toggle {
    width: 34px;
  }

  .header__buttons {
    margin-right: 46px;
    gap: 8px;
  }

  .header__button--login,
  .header__button--registration {
    width: unset;
  }
}

@media (max-width: 420px) {
  .header__container {
    padding: 0 10px;
  }

  .header__wrap {
    gap: 10px;
  }

  .header__logo {
    width: 90px;
  }

  .main-nav-toggle {
    width: 30px;
    right: 31px;
  }

  .header__buttons {
    margin-right: 40px;
    gap: 5px;
  }

  .header__button {
    padding: 10px 8px;
    font-size: 14px;
  }

  .user-block {
    margin-right: 42px;
    gap: 10px;
  }
}