.details {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #F8FAFF;
}

.section-heading--details {
  margin-bottom: 40px;
}

.details-tabs {
  width: 100%;
  height: 727px;
  display: flex;
  gap: 100px;
}

.details-tabs__oblects {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
}

.details-tabs__titles-list {
  width: 345px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.details-tabs__titles-item {
  width: 100%;
}

.details-tabs__button {
  padding: 20px 25px;
  width: 345px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.details-tabs__button--mobile {
  display: none;
}

.details-tabs__label {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 18px 25px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  border-radius: 10px;
  cursor: pointer;
}

.details-tabs__tab-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  opacity: 0.5;
}

#details-tabs-oblect-1:checked~.details-tabs__oblects .details-tabs__label--1,
#details-tabs-oblect-2:checked~.details-tabs__oblects .details-tabs__label--2,
#details-tabs-oblect-3:checked~.details-tabs__oblects .details-tabs__label--3,
#details-tabs-oblect-4:checked~.details-tabs__oblects .details-tabs__label--4,
#details-tabs-oblect-5:checked~.details-tabs__oblects .details-tabs__label--5,
#details-tabs-oblect-6:checked~.details-tabs__oblects .details-tabs__label--6,
#details-tabs-oblect-7:checked~.details-tabs__oblects .details-tabs__label--7,
#details-tabs-oblect-8:checked~.details-tabs__oblects .details-tabs__label--8,
#details-tabs-oblect-9:checked~.details-tabs__oblects .details-tabs__label--9 {
  background: rgba(255, 255, 255, 0.7);
  /* border: 3px solid rgba(255, 255, 255, 0.5); */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

#details-tabs-oblect-1:checked~.details-tabs__oblects .details-tabs__label--1 .details-tabs__tab-title,
#details-tabs-oblect-2:checked~.details-tabs__oblects .details-tabs__label--2 .details-tabs__tab-title,
#details-tabs-oblect-3:checked~.details-tabs__oblects .details-tabs__label--3 .details-tabs__tab-title,
#details-tabs-oblect-4:checked~.details-tabs__oblects .details-tabs__label--4 .details-tabs__tab-title,
#details-tabs-oblect-5:checked~.details-tabs__oblects .details-tabs__label--5 .details-tabs__tab-title,
#details-tabs-oblect-6:checked~.details-tabs__oblects .details-tabs__label--6 .details-tabs__tab-title,
#details-tabs-oblect-7:checked~.details-tabs__oblects .details-tabs__label--7 .details-tabs__tab-title,
#details-tabs-oblect-8:checked~.details-tabs__oblects .details-tabs__label--8 .details-tabs__tab-title,
#details-tabs-oblect-9:checked~.details-tabs__oblects .details-tabs__label--9 .details-tabs__tab-title {
  color: #000000;
  opacity: 1;
}

.details-tabs__content-item {}

.details-tabs__content {
  width: 100%;
}

.details-tabs__content-item {
  display: none;
}


#details-tabs-oblect-1:checked~.details-tabs__content .details-tabs__content-item--1,
#details-tabs-oblect-2:checked~.details-tabs__content .details-tabs__content-item--2,
#details-tabs-oblect-3:checked~.details-tabs__content .details-tabs__content-item--3,
#details-tabs-oblect-4:checked~.details-tabs__content .details-tabs__content-item--4,
#details-tabs-oblect-5:checked~.details-tabs__content .details-tabs__content-item--5,
#details-tabs-oblect-6:checked~.details-tabs__content .details-tabs__content-item--6,
#details-tabs-oblect-7:checked~.details-tabs__content .details-tabs__content-item--7,
#details-tabs-oblect-8:checked~.details-tabs__content .details-tabs__content-item--8,
#details-tabs-oblect-9:checked~.details-tabs__content .details-tabs__content-item--9 {
  display: block;
  height: 100%;
}

.tab__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px;
  gap: 15px;
  width: 100%;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.heading-three {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.tab__text {
  width: 100%;
  margin: 0;
}

.tab__content-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 30px 30px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  flex-grow: 1;
}

.tab__content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 31px;
}

@media (max-width: 1240px) {
  .details-tabs {
    gap: 50px;
  }
  .tab__title {
    padding: 10px 20px 20px;
  }
}

@media (max-width: 1140px) {
  .details-tabs__oblects {
    gap: 0;
  }
  .details-tabs__titles-list {
    width: 320px;
  }

  .details-tabs__button {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .details {
    padding: 46px 0;
  }

  .section-heading--details {
    margin-bottom: 24px;
  }

  .details-tabs {
    gap: 36px;
  }

  .details-tabs__titles-list {
    width: 290px;
  }

  .details-tabs__label {
    padding: 18px;
  }

  .details-tabs__tab-title {
    font-size: 18px;
  }

  .tab__content-block {
    padding: 20px 25px;
  }

  .heading-three {
    font-size: 18px;
  }
}

@media (max-width: 940px) {
  .details-tabs__titles-list {
    width: 220px;
  }

  .details-tabs__label {
    padding: 16px;
  }

  .details-tabs__tab-title {
    font-size: 16px;
  }

  .details-tabs__button {
    padding: 20px;
    font-size: 14px;
  }
}

@media (max-width: 880px) {
  .details-tabs {
    flex-direction: column;
    height: unset;
  }

  .details-tabs__oblects {
    overflow: hidden;
  }

  .details-tabs__titles-list {
    width: 100%;
    flex-direction: row;
    overflow: auto;
    align-items: stretch;
  }

  .details-tabs__titles-list::-webkit-scrollbar {
    width: 7px;
    height: 7px;      
  }
  
  .details-tabs__titles-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #E8EBF5;
    margin-top: 36px;
    margin-bottom: 32px;
  }
  
  .details-tabs__titles-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--main-color);
    cursor: default;
  }

  .details-tabs__label {
    min-width: 130px;
    height: 100%;
    padding: 10px 20px;
  }

  .details-tabs__button {
    display: none;
  }

  .details-tabs__button--mobile {
    display: block;
    width: 250px;
    margin: 0 auto;
    margin-top: 20px;
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;    
  }
}

@media (max-width: 480px) {
  .details-tabs__label {
    min-width: 120px;
    padding: 10px;
  }
}

