.admin-sidebar-open {
    position: absolute;
    min-height: 95vh;
    width: 250px;
    margin-top: 15px!important;
    background: #E3E3E3!important;
    user-select: none;
    padding-top: 10px;
    z-index: 999;
}

.admin-sidebar-close {
    min-height: 95vh;
    width: 60px;
    background: #E3E3E3!important;
    user-select: none;
    padding-top: 20px;
    z-index: 999;
}
   
.background-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
        z-index: 98;
}
.sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid black;
    text-align: center;
    padding-bottom: 10px;
}

.sidebar-header svg {
    margin-top: 10px!important;
    padding-top: 3px;
}

.sidebar-header-title {
    font-size: 24px!important;
    padding-top: 5px;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.sidebar-list-item span {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    margin-left: 12px!important;
}

.admin-sidebar-badge {
    margin-right:15px!important;
}
