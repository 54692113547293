.full-report-head {
  padding: 80px 0 60px;
}

.full-report-head__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.full-report-head__title {
  display: flex;
  align-items: center;
  gap: 15px;
}

.section-heading--full-report-head {
  color: var(--main-color);
}

.full-report-head__wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 120px;
}

.full-report-head__left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
}

.full-report-head__info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.full-report-head__status {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);
}

.full-report-head__status--progress span {
  color: #00E300;
}

.full-report-head__status--success span {
  color: #498EDF;
}

.full-report-head__date-start,
.full-report-head__date-end {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.full-report-head__date-start span,
.full-report-head__date-end span {
  color: #000000;
}

.images-block {
  display: flex;
  gap: 10px;
}

.images-block__big-image {
  width: 300px;
  height: 250px;
}

.images-block__big-image img {
  width: 300px;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
  border-radius: 20px;
  cursor: pointer;
}

.images-block__wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.images-block__wrap img {
  width: 100px;
  height: auto;
  border: 2px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  cursor: pointer;
}

.images-block__more {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.images-block__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2.5px);
  border-radius: 10px;
}

.images-block__next-photos {
  margin: 0;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #FFFFFF;
}

.images-block__no-images {
  margin: 0;
  font-weight: 400;
}

.full-report-head__right {
  width: 100%;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 12px;
  column-gap: 120px;
  flex-wrap: wrap;
}

.full-report-head__parameter {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);
}

.full-report-head__parameter--weight br,
.full-report-head__parameter--transmission br {
  display: none;
}

.full-report-head__parameter span {
  color: #000000;
}

.full-report-head__pay-button {
  width: 405px;
  padding: 25px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  border-radius: 10px;
  color: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 76, 219, 0.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.full-report-details {
  padding-bottom: 100px;
}

.full-report-details__container {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}

.full-report-details__left {
  width: 729px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.full-report-details__right {
  position: sticky;
  top: 0;
  right: 0;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.summary__title {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.summary__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.summary__item {
  position: relative;
  padding-left: 28px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;
}

.summary__list--flaws .summary__item::before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 3px;
  left: 0;
  background-image: url("../../../media/images/new/error.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
}

.summary__list--advantages .summary__item::before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 3px;
  left: 0;
  background-image: url("../../../media/images/new/success.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
}

.summary__list--other .summary__item::before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 3px;
  left: 0;
  background-color: var(--main-color);
  border-radius: 50%;
}

.general-info__icon {
  flex-shrink: 0;
}

@media (max-width: 1240px) {
  .full-report-head__wrap {
    gap: 60px;
  }

  .full-report-head__right {
    column-gap: 60px;
  }
  .full-report-details__right {
    gap: 18px;
  }

  .summary {
    gap: 10px;
  }
}

@media (max-width: 1140px) {
  .full-report-head__right {
    column-gap: 40px;
  }
}

@media (max-width: 1100px) {
  .full-report-head {
    padding: 40px 0 60px;
  }

  .images-block {
    flex-direction: column;
  }

  .images-block__wrap {
    flex-direction: row;
  }

  .images-block__big-image {
    width: 320px;
  }

  .images-block__big-image img {
    width: 100%;
  }

  .full-report-head__parameter {
    font-size: 16px;
  }

  .full-report-head__right {
    row-gap: 10px;
  }
}

@media (max-width: 940px) {
  .full-report-head__parameter--weight br {
    display: block;
  }

  .full-report-head__wrap {
    gap: 40px;
  }

  .full-report-details__container {
    gap: 30px;
  }

  .full-report-head__brand {
    width: 64px;
  }

  .full-report-head__right {
    column-gap: 10px;
  }

  .summary__item {
    padding-left: 24px;
  }

  .summary__list--flaws .summary__item::before,
  .summary__list--advantages .summary__item::before,
  .summary__list--other .summary__item::before {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
  }

  .general-info__content {
    gap: 22px;
  }

  .general-info__icon img {
    width: 16px;
    height: 16px;
  }

  .general-info__title {
    gap: 9px;
  }
}

@media (max-width: 880px) {
  .full-report-head__wrap {
    gap: 30px;
  }

  .full-report-head__info {
    gap: 8px;
  }

  .full-report-head__right {
    row-gap: 8px;
  }

  .full-report-head__parameter--transmission br {
    display: block;
  }

  .images-block__big-image {
    width: 300px;
    height: 200px;
  }

  .images-block__wrap {
    width: 300px;
    gap: 8px;
  }

  .images-block__wrap img {
    width: calc((100% - 2 * 8px) / 3);
  }

  .images-block__more img {
    width: 100%;
  }

  .full-report-details {
    padding-bottom: 60px;
  }

  .full-report-details__container {
    gap: 24px;
  }

  .full-report-details__left {
    width: 510px;
  }

  .general-info__content {
    padding: 20px;
    gap: 20px;
  }

  .general-info__block {
    gap: 6px;
  }
}

@media (max-width: 800px) {
  .full-report-head {
    padding-bottom: 40px;
  }

  .full-report-head__right {
    order: 1;
    height: unset;
  }

  .full-report-details__container {
    flex-direction: column;
  }

  .full-report-details__right {
    position: static;
    order: 1;
  }

  .full-report-details__left {
    order: 2;
    width: 100%;
  }
}

@media (max-width: 660px) {
  .full-report-head__wrap {
    flex-direction: column;
  }

  .images-block__big-image {
    width: 100%;
  }

  .images-block__wrap {
    width: 100%;
  }

  .full-report-head {
    padding-bottom: 20px;
  }

  .full-report-head__right {
    height: 340px;
  }
}

@media (max-width: 480px) {
  .full-report-head__right {
    height: unset;
  }
}