.user-profile {
  padding: 60px 0;
}

.section-heading--user-profile {
  color: #004CDB;
  margin-bottom: 12px;
}

.user-profile__title {
  width: 100%;
  padding-left: 30px;
  margin-bottom: 30px;
}

.user-profile__tab-list {
  display: flex;
  gap: 20px;
}

.user-profile__tab-item { 
  opacity: 0.5;
}

.user-profile__tab-link {
  display: block;
  padding: 8px 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #000000;
}

.user-profile__tab-link--active {
  font-weight: 600;
  color: #004CDB;
  border-bottom: 2px solid #004CDB;
}

.archive {
  padding: 60px 0;
}

.section-heading--archive {
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  .user-profile__title  {
    padding-left: 0;
  }
}