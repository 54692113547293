.reviews {
  padding: 60px 0;
  background: #FFFFFF;
}

.section-heading--reviews {
  margin-bottom: 40px;
}

.reviews__wrap {
  width: 100%;
}

.reviews__list {
  width: 100%;
  display: flex;
  gap: 24px;
}

.review {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 30px;
  gap: 25px;
  width: calc((100% - 2 * 24px) / 3);
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.review__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
}

.review__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__user {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.review__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.review__name {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.review__profession {
  margin: 0;
  opacity: 0.5;
}

.review__stars {
  display: flex;
}

.review__date {
  margin: 0;
  opacity: 0.5;
}

.review__text {
  height: 100%;
}

.review__image-wrap {
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.review__picture {
  height: 100%;
}

.review__img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1140px) {
  .review {
    padding: 20px;
    gap: 18px;
  }
  .review__wrap {
    gap: 18px;
  }
}

@media (max-width: 1100px) {
  .reviews {
    padding: 46px 0;
  }

  .section-heading--reviews {
    margin-bottom: 24px;
  }

  .review {
    padding: 16px;
  }

  .review__user {
    padding: 0;
    gap: 5px;
  }

  .review__name {
    font-size: 18px;
  }

  .review__info {
    gap: 14px;
  }

  .review__image-wrap {
    height: 120px;
  }
}

@media (max-width: 800px) {
  .reviews__list {
    flex-direction: column;
  }

  .review {
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    gap: 25px;
  }

  .review:nth-child(2n) .review__wrap {
    order: 1;
  }

  .review__image-wrap {
    width: calc((100% - 25px) / 2);
    height: unset;
  }
}

@media (max-width: 560px)  {
  .review {
    flex-direction: column;
  }
  .review:nth-child(2n) .review__wrap {
    order: unset;
  }

  .review__image-wrap {
    width: 100%;
  }
}