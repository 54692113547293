.faq-container {
    width: 50vw;
    margin-top: 150px!important;
    margin-bottom: 150px!important;
    margin: 150px auto!important; 
}

.faq-title-text {
    font-size:  34px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.faq-question-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.faq-answer-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
}

.answer-second-title{
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 20px!important;
}

.faq-answer-image {
    width: 80%;
    background: linear-gradient(180deg, rgba(238, 237, 237, 0.3), rgba(239, 238, 238, 1))!important;
    padding: 15px;
}

.support-text {
    margin-top: 10px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

@media (max-width: 900px) {
    .faq-container {
        width: 95vw;
        margin: 40px auto!important; 
    }
}

@media (max-width: 600px) {
    .faq-container {
        width: 80vw;
        margin-top: 150px!important;
        margin-bottom: 150px!important;
        margin: 150px auto!important; 
    }
    .faq-answer-text {
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 400!important;
        font-size: 14px!important;
    }
    .faq-title-text {
        font-size: 24px!important;
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 700!important;
    }
    .answer-second-title{
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 700!important;
        font-size: 16px!important;
    }
    .faq-question-text {
        font-size: 16px!important;
    }

}