.tickets__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2.5px);
  z-index: 1;
}

.ticket-modal {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  background: #F8FAFF;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  z-index: 2;
}

.ticket-modal__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.ticket-modal__wrap-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ticket-modal__close-button {
  width: 24px;
  height: 24px;
  background-image: url('../../../../../../media/images/new/close-button.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px 22px;
}

.ticket-modal__form-wrap {
  width: 100%;
}

.ticket-modal__messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ticket-modal__message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 14px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.ticket-modal__username {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.ticket-modal__message-text {
  margin: 0;
}

.tickets__button--sendmessage {
  width: 193px;
}

.tickets__button--close {
  width: 193px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #FF0000;
  background: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.system-message {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 20px;

  width: 578px;
  background: rgba(0, 76, 219, 0.1);
  border: 1px solid rgba(0, 76, 219, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.system-message__title {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.system-message__text {
  margin: 0;
}

@media (max-width: 800px) {
  .ticket-modal {
    border-radius: 10px;
  }

  .system-message {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
  }

  .ticket-modal__message {
    border-radius: 10px;
  }
}