.tab__content-block--service {
  gap: 20px;
}

.tab-service__list {
  display: flex;
  flex-direction: column;
  gap: 29px;
}

.tab-service__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-service__item-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tab-service__date,
.tab-service__service,
.tab-service__company,
.tab-service__distance,
.tab-service__rollback {
  margin: 0;
}

.tab-service__service {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.tab-service__company {
  display: flex;
  gap: 10px;
}

.tab-service__item-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab-service__distance {
  text-align: right;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.tab-service__distance--rollback {
  color: #FF0000;
}

.tab-service__rollback {
  color: #FF0000;
}