.fines-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fines-history__title {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fines-history__count {
  width: 239px;
  padding: 14px 20px;
  font-weight: 400;
  border-radius: 10px;
  backdrop-filter: blur(20px);
}

.fines-history__count--error {
  background: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #FF0000;
}

.fines-history__count--success {
  background: rgba(0, 227, 0, 0.05);
  border: 2px solid rgba(0, 227, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #00E300;
}

.fines-history__list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.fines-history__item {
  width: calc((100% - 20px) / 2);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 14px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.fines-history__parameter {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.fines-history__parameter span {
  color: #000000;
}

.fines-history__more {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fines-history__button-more {
  padding: 12px 40px;
  border-radius: 10px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-size: 15px;
  line-height: 120%;
  font-weight: 600;
  color: var(--main-color);
  background: #FFFFFF;
  border: 1px solid var(--main-color);
  border-radius: 8px;
}

@media (max-width: 880px) {
  .fines-history__list {
    gap: 16px;
  }
  .fines-history__item {
    padding: 20px;
    gap: 10px;
    border-radius: 10px;
  }
}

@media (max-width: 660px) {
  .fines-history__title {
    padding: 20px 0;
  }

  .fines-history__list {
    flex-direction: column;
  }

  .fines-history__item {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .fines-history__title  {
    flex-direction: column;
    gap: 18px;
    padding-bottom: 10px;
  }

  .fines-history__count {
    padding: 10px 20px;
  }
}
