.restrict-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.restrict-history__title {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.restrict-history__count {
  padding: 14px 20px;
  font-weight: 400;
  border-radius: 10px;
  backdrop-filter: blur(20px);
}

.restrict-history__count--error {
  background: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #FF0000;
}

.restrict-history__count--success {
  background: rgba(0, 227, 0, 0.05);
  border: 2px solid rgba(0, 227, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #00E300;
}


.restrict-history__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 14px;
}

.restrict-history__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 20px;
  width: calc((100% - 14px) / 2);
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.restrict-history__parameter {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.restrict-history__parameter span {
  color: #000000;
}

@media (max-width: 880px) {
  .restrict-history__list {
    flex-direction: column;
  }

  .restrict-history__item {
    width: 100%;
    padding: 20px;
    gap: 10px;
    border-radius: 10px;
  }
}

@media (max-width: 660px) {
  .restrict-history__title {
    padding: 20px 0;
  }
}

@media (max-width: 420px) {
  .restrict-history__title {
    flex-direction: column;
    gap: 18px;
    padding-bottom: 10px;
  }

  .restrict-history__count {
    padding: 10px 20px;
  }
}