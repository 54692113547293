/* .custom-text-field {
    background-color: #E2E2E2;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    width: 540px;
    height: 50px;
} */

/* .custom-text-field input {
    padding-left: 8px;
} */


/* .link-under-field {
    font-size: 16px !important;
    font-family: 'Ysabeau Infant', sans-serif !important;
    font-weight: 700 !important;
} */

/* .submit-field-button {
    background: #498EDF !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    color: rgb(255, 255, 255) !important;
    height: 50px;
    font-family: 'Ysabeau Infant', sans-serif !important;
    font-weight: 700 !important;
    position: relative;
    top: -1px;
} */

/* .submit-field-button:hover {
    background: #277FE4 !important;
} */

.check-auto__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.check-auto__tab-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.select-code-type {
  margin: 0;
  padding: 8px 2px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #000000;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.select-code-type--active {
  font-weight: 600;
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
}

.check-auto__input-wrap {
  width: 100%;
  display: flex;
  gap: 20px;
}

.check-auto__input {
  width: 100%;
  padding: 20px 30px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.check-auto__input:focus {
  outline: none;
  border: 1px solid var(--main-color);
}

.check-auto__input::placeholder {
  opacity: 0.5;
}

.check-auto__button {
  width: 155px;
  flex-shrink: 0;
  padding: 20px; 
  font-weight: 600;  
  line-height: 120%;
  color: #FFFFFF;
  background: var(--main-color);
  border-radius: 10px;
}

.custom-helper-text {
  display: inline-block;
  margin-top: 5px;
  color: #FF0000;   
}

@media (max-width: 480px) {
  .check-auto__input-wrap {
    flex-direction: column;
  }

  .check-auto__button {
    padding: 14px 20px;
  }
}

/* @media (max-width: 700px) {
    .custom-text-field {
        width: 340px;
    }

    .link-under-field {
        font-size: 14px !important;
    }
} */

/* @media (max-width: 465px) {
    .custom-text-field {
        width: 230px;
        font-size: 14px !important;
    }

    .custom-text-field {
        width: 230px;
    }

    .submit-field-button {
        font-size: 12px !important;
    }
} */

/* @media (max-width: 371px) {
    .custom-text-field {
        width: 50vw;
        font-size: 12px !important;
    }
} */