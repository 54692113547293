.profile-title-container {
    text-align: center;
    margin-top: 50px!important;
    margin-bottom: 25px!important;
}

.profile-title {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 24px!important;
}

.profile-request-form {
    height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(180deg, #F4F9FF 0%, #F4F9FF 0.01%, #CCE4FF 100%);
    border-radius: 7px;
}

.profile-submit-request-btn {
    background: #498EDF!important;
    height: 55px;
    color: white!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.profile-btns-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto!important;
    margin-top: 45px!important;
}

.profile-navigate-btn {
    background: #498EDF!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    color: white!important;
    height: 45px;
    width: 45%;
}
.profile-navigate-btn:hover {
    background: #277FE4!important;
}

.search-filter-container {
    display: flex;
    justify-content: left;
    margin-top: 20px!important;
}
.search-filter-field {
    width: 250px;
    margin-left: auto!important;
}
.search-filter-btn {
    background: #498EDF!important;
    color: white!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.report-pagination-btns {
    background: #498EDF!important;
    color: white!important;
    margin-bottom: 20px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}
.report-pagination-btns:hover {
    background: #277FE4!important;
}
.report-pagination-btns:disabled {
    background: #b0b0b0!important;
}

@media (max-width: 700px) {
    .profile-navigate-btn {
        font-size: 10px!important;
    }
}

@media (max-width: 450px) {
    .search-filter-field {
        width: 90%;
    }
}