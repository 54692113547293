.devices {
  padding: 60px 0 100px;
  background: #F8FAFF;
}

.section-heading--devices {
  margin-bottom: 50px;
}

.devices__wrap {
  width: 100%;
}

.devices__list {
  width: 100%;
  display: flex;
  padding: 0px;
  gap: 30px;
}

.devices__item {
  width: calc((100% - 2 * 30px) / 3);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 25px 30px;
  gap: 25px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.devices__icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.devices__icon--one {
  background-image: url("../../../media/images/new/icon-smartphone.svg");
}

.devices__icon--two {
  background-image: url("../../../media/images/new/icon-money.svg");
}

.devices__icon--three {
  background-image: url("../../../media/images/new/icon-support.svg");
}

.devices__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0px 0px;
  gap: 10px;
}

.devices__title {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.devices__text {
  margin: 0;
}

@media (max-width: 1240px) {
  .devices__item {
    padding: 20px;
    gap: 15px;
    border-radius: 10px;
  }
}

@media (max-width: 1100px) {
  .devices {
    padding: 46px 0;
  }

  .section-heading--devices {
    margin-bottom: 24px;
  }

  .devices__item {
    padding: 10px;
    gap: 8px;
  }

  .devices__icon {
    width: 25px;
    height: 25px;
  }

  .devices__title {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .devices__list {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .devices__item {
    width: 100%;
    gap: 12px;
  }

  .devices__icon {
    margin-top: 0;
  }
}