
.payment-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto!important;
    box-shadow: 0px 0px 4px 2px #929292;
    width: 80%;
    padding: 15px;
    border-radius: 15px;
    margin-top: 20px!important;
    user-select: none;
    cursor: pointer;
}

.payment-card-title {
    font-size: 22px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.payment-card-header {
    display: flex;
    flex-direction: column;
}

.payment-card-price-container {
    display: flex;
    align-items: center;
}

.payment-card-time {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
}

.payment-card-price {
    font-size: 24px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.withdraw-color {
    background: rgb(255,103,103)!important;
    background: linear-gradient(90deg, rgba(255,103,103,1) 0%, rgba(255,89,64,1) 100%)!important;
}
.withdraw-color:hover {
    background: rgb(255, 82, 82)!important;
    background: linear-gradient(90deg, rgb(255, 76, 76) 0%, rgb(255, 58, 28) 100%)!important;
}

.payment-color {
    background: rgb(111,255,103)!important;
    background: linear-gradient(90deg, rgba(111,255,103,1) 0%, rgba(84,255,64,1) 100%)!important;
}
.payment-color:hover {
    background: rgb(83, 255, 73)!important;
    background: linear-gradient(90deg, rgb(87, 236, 79) 0%, rgb(53, 228, 33) 100%)!important;
}

@media (max-width: 1650px) {
    .payment-card-title {
        font-size: 18px!important;
    }
    .payment-card-price {
        font-size: 22px!important;
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 700!important;
    }
    
}

@media (max-width: 1250px) {
    .payment-card-title {
        font-size: 16px!important;
    }
    .payment-card-price {
        font-size: 18px!important;
    }
}


@media (max-width: 430px) {
    .payment-card-title {
        font-size: 14px!important;
    }
    .payment-card-time {
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 400!important;
        font-size: 12px!important;

    }
    .payment-card-price {
        font-size: 16px!important;
    }
}
