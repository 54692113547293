.swiper-slider {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.swiper-slider__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(25px);
}

.swiper-slider__wrap {
  position: absolute;
  width: 850px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.report-preview-swiper {
  display: flex;
  align-items: center;
  text-align: center;
  height: 400px;
}

.report-preview-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-preview-image {
  user-select: none;
  cursor: pointer;
  height: 95%;
  border-radius: 15px !important;
  border: 6px solid #8C8C8C;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-slider__close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50px;
  right: 50px;
}

.swiper-slider__close-icon {
  width: 100%;
  height: 100%;
}

@media (max-width: 1100px) {
  .swiper-slider__wrap {
    width: 650px;
  }
}

@media (max-width: 800px) {
  .swiper-slider__wrap {
    width: 450px;
  }
}