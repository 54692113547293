.favorites__wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.favorites__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.favorites__item {
  position: relative;
  width: calc((100% - 20px) / 2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 14px;
  isolation: isolate;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.favorites__date,
.favorites__number,
.favorites__vehicle {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.favorites__date span,
.favorites__number span,
.favorites__vehicle span {
  font-weight: 400;
  color: #000000;
}

.favorites__button {
  width: 207px;
  padding: 16px 40px;
  margin-top: 10px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #FFFFFF;
  background: var(--main-color);
  border-radius: 10px;
}

.favorites__add {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 20px;
  top: 20px;
}

@media (max-width: 800px) {
  .favorites__list {
    flex-direction: column;
  }

  .favorites__item {
    width: 100%;
  }
}