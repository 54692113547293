.ticket-system {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.requests {
  width: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.requests__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.requests__title {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.requests__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.request {
  width: 100%;
  padding: 20px;
  display: flex;
  gap: 40px;
  cursor: pointer;
}

.request--answered {
  background: rgba(0, 227, 0, 0.05);
  border: 2px solid rgba(0, 227, 0, 0.05);
  border-radius: 10px;
}

.request--pending {
  background: rgba(255, 195, 0, 0.05);
  border: 2px solid rgba(255, 195, 0, 0.05);
  border-radius: 10px;
}

.request__theme,
.request__answer,
.request__new-messages {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.request__theme span,
.request__new-messages span {
  color: #000000;
}

.request__answer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.request__answer--closed {}

.answer-pending {
  font-weight: 400;
  color: #FFC300;
}

.tickets__empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tickets__empty-text {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 800px) {
  .tickets__form {
    flex-direction: column;
  }

  .tickets__button--create {
    align-self: flex-end;
  }

  .requests {
    border-radius: 10px;
  }

  .request {
    gap: 22px;
  }
}

@media (max-width: 560px) {
  .tickets__inputs-wrap {
    flex-direction: column;
  }

  .request {
    flex-direction: column;
    gap: 10px;
    padding: 14px;
  }
}