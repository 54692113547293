.short-block--commercial {
  background: none;
}

.short-commercial-use {
  padding: 0;
  overflow: hidden;
}

.short-commercial-use__image-wrap {
  width: 100%;
}