.profile-sidebar-container {
    min-height: 95vh;
    width: 250px;
    background: #e4e4e4!important;
    box-shadow: 0px 0px 6px 2px rgb(161, 161, 161);
    margin-left: 10px!important;
    margin-top: 10px!important;
    margin-bottom: 10px!important;
    border-radius: 15px!important;
    z-index: 999!important;
    user-select: none;
}

.background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 98;
}

.profile-sidebar-header {
    display: flex;
    flex-direction: row;
}


.profile-sidebar-header-closed {
    display: flex;
    flex-direction: column!important;
    justify-content: center;
    align-items: center;
}

.profile-sidebar-closed {
    width: 60px;
    position: relative;
    transition: height 0.5s ease;
    margin-top: 15px!important;
}

.profile-sidebar-open {
    position: absolute;
    padding: 1px;
    border-radius: 50%;
    margin-top: 15px!important;
}


.profile-sidebar-user-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px!important; 
    border-bottom: 2px solid #a1a1a1!important;
}

.profile-user-avatar {
    width: 50px;
    border-radius: 50%;

}

.user-avatar-border {
    border-radius: 50%;
    background: linear-gradient(to right, red, orange);
    padding: 2px;
}

.profile-user-avatar-closed {
    width: 50px;
    border-radius: 50%;
    margin-bottom: 15px!important;
}

.profile-user-avatar:hover {
    cursor: pointer;  
}

.profile-user-account {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    margin-left: 20px!important;
}

.profile-sidebar-username {
    width: 105px;
    overflow: hidden;
    word-wrap: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    margin-left: 20px!important;
}

.profile-user-account .profile-user-account-price {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    color: #4CDF49;
}

.profile-user-account .profile-user-request-quantity {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    text-decoration: underline;
    color: #498EDF;
}

.profile-sidebar-item {
    margin-bottom: 3px!important;
    height: 45px;
}

.profile-sidebar-item:hover {
    background: #f0f0f0!important;
    margin-bottom: 3px!important;
    height: 45px;
}

.profile-item-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin-left: 15px!important;
}

.profile-sidebar-icons-open {
    position: relative;
    left: 15px;
    background: #ffffff;
    border-radius: 50%;
    padding: 2px;
}

.profile-item-text-close {
    display: none;
}

.profile-item-text .active {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin-left: 15px!important;
}

.outlet-profile-container {
    flex: 1;
    min-height: 100vh;
}

@media (max-width: 950px) {
    .profile-sidebar-open {
        position: absolute;
        left: 0;
        z-index: 999!important;
        transition: height 0.5s ease;
    }

}