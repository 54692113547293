.short-block {
  flex-grow: 1;
  padding: 30px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  border-radius: 20px;
}

.short-block-accidents {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.short-block-accidents__left {
  width: 206px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-shrink: 0;
}

.short-block-accidents__title {
  margin: 0;
  margin-bottom: 10px;
}

.short-block-accidents__item {
  font-weight: 400;
}

.short-block-accidents__text {
  margin: 0;
}

.short-block-accidents__right {
  width: 100%;
}

@media (max-width: 1100px) {
  .short-block {
    padding: 20px;
  }
}

@media (max-width: 940px) {
  .short-block-accidents {
    gap: 4px;
  }

  .short-block-accidents__left {
    width: calc((100% - 4px) / 2);
  }
}

@media (max-width: 420px) {
  .short-block-accidents {
    flex-direction: column;
  }

  .short-block-accidents__left {
    width: 100%;
  }

  .short-block-accidents__right img {
    width: 100%;
  }
}