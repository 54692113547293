.benefit {
  padding: 60px 0;
  background: #FFFFFF;
}

.section-heading--benefit {
  margin-bottom: 40px;
}

.section-heading span {
  color: var(--main-color);
}

.benefit__list {
  width: 100%;
  display: flex;
  gap: 30px;
}

.benefit__item {
  width: calc((100% - 2 * 30px) / 3);
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.benefit__title {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.benefit__text {
  height: 100%;
  margin: 0;
  margin-bottom: 25px;
}

.benefit__note {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 0px;
  background: rgba(255, 195, 0, 0.02);
  border: 2px solid rgba(255, 195, 0, 0.05);
  border-radius: 10px;
}

.benefit__note-text {
  font-weight: 400;
}

.benefit__note-icon {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.benefit__note-icon--one {
  background-image: url("../../../media/images/new/icon-benefit-one.svg");
}

.benefit__note-icon--two {
  background-image: url("../../../media/images/new/icon-benefit-two.svg");
}

.benefit__note-icon--three {
  background-image: url("../../../media/images/new/icon-benefit-three.svg");
}

@media (max-width: 1100px) {
  .benefit {
    padding: 46px 0;
  }

  .section-heading--benefit {
    margin-bottom: 24px;
  }

  .benefit__list {
    gap: 25px;
  }

  .benefit__item {
    width: calc((100% - 2 * 25px) / 3);
    padding: 20px;
  }

  .benefit__title {
    font-size: 18px;
  }

  .benefit__text {
    margin-bottom: 15px;
  }

  .benefit__note {
    padding: 10px;
    align-items: flex-start;
  }
}

@media (max-width: 800px) {
  .benefit__list {
    flex-direction: column;
  }

  .benefit__item {
    width: 100%;
  }

  .benefit__note {
    justify-content: flex-start;
    align-items: center;
  }

  .benefit__note-icon {
    order: 1;
  }

  .benefit__note-text {
    order: 2;
  }
}