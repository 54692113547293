

.help-modal-container {
    background: white !important;
    margin: auto !important;
    width: 500px;
    margin-top: 50px!important;
    position: fixed;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    padding: 15px;
    border-radius: 10px;
}

.help-modal-image-container {
    width: 80%;
    margin: 0 auto!important;
    text-align: center;
    background: linear-gradient(180deg, rgba(238, 237, 237, 0.3), rgba(239, 238, 238, 1))!important;
    border-radius: 16px;
}

.help-modal-image {
    width: 327px!important;
    margin: 0 auto!important;
    border-radius: 7px;
}

.main-title-help-modal {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 24px!important;
    padding-bottom: 10px;
}

.title-help-modal {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 20px!important;
    padding-top: 10px;
}

.text-help-modal {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    font-size: 18px!important;
}

.help-modal-close-icon {
    font-size: 26px!important;
    position: relative;
    left: 35px;
    bottom: 10px;
    cursor: pointer;
}


@media (max-width: 550px) {
    .help-modal-container {
        width: 80%;
    }
    .help-modal-image {
        width: 75%!important;
        margin: 0 auto!important;
        border-radius: 7px;
    }
}
