.short-operation-history {
  padding: 0 30px;
}

.operation-table {
  width: 100%;
}

.operation-table__head-cell {
  position: relative;
  padding-left: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-size: 14px;
  border-left: 2px solid rgba(218, 215, 215, 0.5);
}

.operation-table__head-cell::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  top: 33px;
  left: -8px;
  border-radius: 50%;
  background-color:  rgb(27, 143, 238);
  border: 2px solid #fff;
  z-index: 1;
}

.operation-table__head-cell::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 36px;
  left: -2px;
  background-color:  rgb(27, 143, 238);
}

.operation-table__head-title {
  margin: 0;
  font-size: 16px;
}

.operation-table__parameter {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.operation-table__parameter span {
  color: #000;
}

.operation-table__button {
  padding: 8px 10px;
  font-weight: 400;
  border-radius: 15px;
  background-color: rgba(218, 215, 215, 0.5);
}

.operation-table__row {
  width: 100%;
}

.operation-table__cell--right {
  position: relative;
  padding-left: 15px;
  border-left: 2px solid rgba(218, 215, 215, 0.5);
}

.operation-table__cell--right::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 6px;
  left: -6px;
  border-radius: 50%;
  background-color:  rgb(27, 143, 238);
  border: 2px solid #fff;
  z-index: 1;
}

.operation-table__cell--right::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  top: 6px;
  left: -2px;
  background-color:  rgb(27, 143, 238);
}

.operation-table__cell-title {
  margin: 0;
  margin-bottom: 5px;
  font-size: 15px;
}

.operation-table__cell-text {
  margin: 0;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
}

.operation-table__date {
  vertical-align: top;
  font-size: 14px;
  font-weight: 400;
}

.operation-table__date span {
  color: rgba(0, 0, 0, 0.5);
}

