.report-block-container {
    position: relative;
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding-bottom: 15px;
    margin-top: 25px!important;
}

.report-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.report-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.report-badge {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    width: 255px;
    height: 50px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 25px;
    align-items: center;
}
.report-badge-img {
    border-radius: 50%;
    width: 35px;
    margin-left: 10px;
    margin-right: 5px;
}
.report-badge-text {
    color: #fff;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin: 0 auto;
}

.report-content-border {
    margin-top: 15px;
    background: #e3e3e3; 
    width: 100%; 
    height: 1px;
}

.report-item-link {
    color: #498EDF;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    word-break: break-all;
    overflow: hidden;
    cursor: pointer;
}

.mileage-block {
    display: flex;
    flex-direction: row;
    padding: 3px;
}

.switch-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 15px!important;
}

.switch-content-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

@media (max-width: 600px) {
    .report-badge {
        display: none;
    }
}

.css-ovwgyn {
    margin-right: 15px;
}