
.report-navigation-btn {
    background: #498EDF!important;
    color: white!important;
    width: 45%;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    height: 50px;
}
.report-navigation-btn:hover {
    background: #277FE4!important;
}

.report-btns-grid {
    display: flex;
    justify-content: space-around;
    margin-top: 10px!important;
}

.open-report-navigation {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}