.tickets__message {
  width: 100%;
}

.tickets__form {
  width: 100%;
  display: flex;
  gap: 15px;
}

.tickets__inputs-wrap {
  width: 100%;
  margin: 0;
  display: flex;
  gap: 15px;
}

.tickets__input-wrap {
  position: relative;
}

.tickets__input-wrap--theme {
  width: 216px;
  flex-shrink: 0;
}

.tickets__input-wrap--message {
  flex-grow: 1;
}

.tickets__input {
  padding: 16px 30px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.tickets__input--theme,
.tickets__input--message {
  width: 100%;
}

.tickets__input:focus {
  outline: none;
  border: 1px solid var(--main-color);
}

.tickets__input--error {
  border: 1px solid #FF0000;
}

.tickets__message-error {
  color: #FF0000;
}

.tickets__button {
  flex-shrink: 0;
  padding: 16px 30px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #FFFFFF;
  border-radius: 10px;
}

.tickets__button--create {
  width: 179.5px;
}