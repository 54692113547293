.upload-avatar-btn {
    background: #498EDF!important;
    color: white!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    height: 45px;
}

.upload-avatar-btn:hover {
    background: #277FE4!important;
}

.cancel-avatar-btn {
    background: #E4E4E4!important;
    color: black!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    height: 45px;
    margin-left: 10px!important;
}

.upload-avatar-content {
    width: 100%!important;
}

.avatar-editor {
    width: 100%!important;
    height: 100%!important;
}

.upload-avatar-title {
    text-align: center;
    font-size: 24px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

@media (max-width: 400px) {
    .upload-avatar-title {
        font-size: 20px!important;
    }    
}