:root {
    --grid-gutter: 0.8rem
}

.report-crash {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width: 1400px) {
    .report-crash {width: 90%}
}

@media(max-width: 1200px) {
    .report-crash {width: 95%}
}

@media(max-width: 1000px) {
    .report-crash {width: 95%}
}

@media(max-width: 600px) {
    .report-crash {width: 100%}
}

.report-crash path {
    stroke: #000;
    fill: #fff
}

.report-crash.dmg-10 .dmg__section-0 {
    fill: #feda6b
}

.report-crash.dmg-20 .dmg__section-0 {
    fill: #ff5166
}

.report-crash.dmg-11 .dmg__section-1 {
    fill: #feda6b
}

.report-crash.dmg-21 .dmg__section-1 {
    fill: #ff5166
}

.report-crash.dmg-12 .dmg__section-2 {
    fill: #feda6b
}

.report-crash.dmg-22 .dmg__section-2 {
    fill: #ff5166
}

.report-crash.dmg-13 .dmg__section-3 {
    fill: #feda6b
}

.report-crash.dmg-23 .dmg__section-3 {
    fill: #ff5166
}

.report-crash.dmg-14 .dmg__section-4 {
    fill: #feda6b
}

.report-crash.dmg-24 .dmg__section-4 {
    fill: #ff5166
}

.report-crash.dmg-15 .dmg__section-5 {
    fill: #feda6b
}

.report-crash.dmg-25 .dmg__section-5 {
    fill: #ff5166
}

.report-crash.dmg-16 .dmg__section-6 {
    fill: #feda6b
}

.report-crash.dmg-26 .dmg__section-6 {
    fill: #ff5166
}

.report-crash.dmg-17 .dmg__section-7 {
    fill: #feda6b
}

.report-crash.dmg-27 .dmg__section-7 {
    fill: #ff5166
}

.report-crash.dmg-18 .dmg__section-8 {
    fill: #feda6b
}

.report-crash.dmg-28 .dmg__section-8 {
    fill: #ff5166
}

.report-crash.dmg-19 .dmg__section-9 {
    fill: #feda6b
}

.report-crash.dmg-29 .dmg__section-9 {
    fill: #ff5166
}

.report-crash.dmg-110 .dmg__section-10 {
    fill: #feda6b
}

.report-crash.dmg-210 .dmg__section-10 {
    fill: #ff5166
}

.report-crash.dmg-111 .dmg__section-11 {
    fill: #feda6b
}

.report-crash.dmg-211 .dmg__section-11 {
    fill: #ff5166
}

.report-crash.dmg-112 .dmg__section-12 {
    fill: #feda6b
}

.report-crash.dmg-212 .dmg__section-12 {
    fill: #ff5166
}

.report-crash.dmg-113 .dmg__section-13 {
    fill: #feda6b
}

.report-crash.dmg-213 .dmg__section-13 {
    fill: #ff5166
}

.report-crash.dmg-114 .dmg__section-14 {
    fill: #feda6b
}

.report-crash.dmg-214 .dmg__section-14 {
    fill: #ff5166
}

.report-crash.dmg-115 .dmg__section-15 {
    fill: #feda6b
}

.report-crash.dmg-215 .dmg__section-15 {
    fill: #ff5166
}

.report-crash.dmg-116 .dmg__section-16 {
    fill: #feda6b
}

.report-crash.dmg-216 .dmg__section-16 {
    fill: #ff5166
}

.report-crash.dmg-117 .dmg__section-17 {
    fill: #feda6b
}

.report-crash.dmg-217 .dmg__section-17 {
    fill: #ff5166
}

.report-crash.dmg-118 .dmg__section-18 {
    fill: #feda6b
}

.report-crash.dmg-218 .dmg__section-18 {
    fill: #ff5166
}

.report-crash.dmg-119 .dmg__section-19 {
    fill: #feda6b
}

.report-crash.dmg-219 .dmg__section-19 {
    fill: #ff5166
}

.report-crash.dmg-120 .dmg__section-20 {
    fill: #feda6b
}

.report-crash.dmg-220 .dmg__section-20 {
    fill: #ff5166
}

.report-crash.dmg-121 .dmg__section-21 {
    fill: #feda6b
}

.report-crash.dmg-221 .dmg__section-21 {
    fill: #ff5166
}

.report-crash.dmg-122 .dmg__section-22 {
    fill: #feda6b
}

.report-crash.dmg-222 .dmg__section-22 {
    fill: #ff5166
}

.report-crash.dmg-123 .dmg__section-23 {
    fill: #feda6b
}

.report-crash.dmg-223 .dmg__section-23 {
    fill: #ff5166
}

.report-crash.dmg-124 .dmg__section-24 {
    fill: #feda6b
}

.report-crash.dmg-224 .dmg__section-24 {
    fill: #ff5166
}

.report-crash.dmg-125 .dmg__section-25 {
    fill: #feda6b
}

.report-crash.dmg-225 .dmg__section-25 {
    fill: #ff5166
}

.report-crash.dmg-126 .dmg__section-26 {
    fill: #feda6b
}

.report-crash.dmg-226 .dmg__section-26 {
    fill: #ff5166
}

.report-crash.dmg-127 .dmg__section-27 {
    fill: #feda6b
}

.report-crash.dmg-227 .dmg__section-27 {
    fill: #ff5166
}

.report-crash.dmg-128 .dmg__section-28 {
    fill: #feda6b
}

.report-crash.dmg-228 .dmg__section-28 {
    fill: #ff5166
}

.report-crash.dmg-129 .dmg__section-29 {
    fill: #feda6b
}

.report-crash.dmg-229 .dmg__section-29 {
    fill: #ff5166
}

.report-crash.dmg-130 .dmg__section-30 {
    fill: #feda6b
}

.report-crash.dmg-230 .dmg__section-30 {
    fill: #ff5166
}

.report-crash.dmg-131 .dmg__section-31 {
    fill: #feda6b
}

.report-crash.dmg-231 .dmg__section-31 {
    fill: #ff5166
}

.report-crash.dmg-132 .dmg__section-32 {
    fill: #feda6b
}

.report-crash.dmg-232 .dmg__section-32 {
    fill: #ff5166
}

.report-crash.dmg-133 .dmg__section-33 {
    fill: #feda6b
}

.report-crash.dmg-233 .dmg__section-33 {
    fill: #ff5166
}

.report-crash.dmg-134 .dmg__section-34 {
    fill: #feda6b
}

.report-crash.dmg-234 .dmg__section-34 {
    fill: #ff5166
}

.report-crash.dmg-135 .dmg__section-35 {
    fill: #feda6b
}

.report-crash.dmg-235 .dmg__section-35 {
    fill: #ff5166
}

.report-crash.dmg-136 .dmg__section-36 {
    fill: #feda6b
}

.report-crash.dmg-236 .dmg__section-36 {
    fill: #ff5166
}

.report-crash.dmg-137 .dmg__section-37 {
    fill: #feda6b
}

.report-crash.dmg-237 .dmg__section-37 {
    fill: #ff5166
}

.report-crash.dmg-138 .dmg__section-38 {
    fill: #feda6b
}

.report-crash.dmg-238 .dmg__section-38 {
    fill: #ff5166
}

.report-crash.dmg-139 .dmg__section-39 {
    fill: #feda6b
}

.report-crash.dmg-239 .dmg__section-39 {
    fill: #ff5166
}

.report-crash.dmg-140 .dmg__section-40 {
    fill: #feda6b
}

.report-crash.dmg-240 .dmg__section-40 {
    fill: #ff5166
}

.report-crash.dmg-141 .dmg__section-41 {
    fill: #feda6b
}

.report-crash.dmg-241 .dmg__section-41 {
    fill: #ff5166
}

.report-crash.dmg-142 .dmg__section-42 {
    fill: #feda6b
}

.report-crash.dmg-242 .dmg__section-42 {
    fill: #ff5166
}

.report-crash.dmg-143 .dmg__section-43 {
    fill: #feda6b
}

.report-crash.dmg-243 .dmg__section-43 {
    fill: #ff5166
}

.report-crash.dmg-144 .dmg__section-44 {
    fill: #feda6b
}

.report-crash.dmg-244 .dmg__section-44 {
    fill: #ff5166
}

.report-crash.dmg-145 .dmg__section-45 {
    fill: #feda6b
}

.report-crash.dmg-245 .dmg__section-45 {
    fill: #ff5166
}

.report-crash.dmg-146 .dmg__section-46 {
    fill: #feda6b
}

.report-crash.dmg-246 .dmg__section-46 {
    fill: #ff5166
}

.report-crash.dmg-147 .dmg__section-47 {
    fill: #feda6b
}

.report-crash.dmg-247 .dmg__section-47 {
    fill: #ff5166
}

.report-crash.dmg-148 .dmg__section-48 {
    fill: #feda6b
}

.report-crash.dmg-248 .dmg__section-48 {
    fill: #ff5166
}

.report-crash.dmg-149 .dmg__section-49 {
    fill: #feda6b
}

.report-crash.dmg-249 .dmg__section-49 {
    fill: #ff5166
}

.report-crash.dmg-150 .dmg__section-50 {
    fill: #feda6b
}

.report-crash.dmg-250 .dmg__section-50 {
    fill: #ff5166
}

.report-crash.dmg-151 .dmg__section-51 {
    fill: #feda6b
}

.report-crash.dmg-251 .dmg__section-51 {
    fill: #ff5166
}

.report-crash.dmg-152 .dmg__section-52 {
    fill: #feda6b
}

.report-crash.dmg-252 .dmg__section-52 {
    fill: #ff5166
}

.report-crash.dmg-153 .dmg__section-53 {
    fill: #feda6b
}

.report-crash.dmg-253 .dmg__section-53 {
    fill: #ff5166
}

.report-crash.dmg-154 .dmg__section-54 {
    fill: #feda6b
}

.report-crash.dmg-254 .dmg__section-54 {
    fill: #ff5166
}

.report-crash.dmg-155 .dmg__section-55 {
    fill: #feda6b
}

.report-crash.dmg-255 .dmg__section-55 {
    fill: #ff5166
}

.report-crash.dmg-156 .dmg__section-56 {
    fill: #feda6b
}

.report-crash.dmg-256 .dmg__section-56 {
    fill: #ff5166
}

.report-crash.dmg-157 .dmg__section-57 {
    fill: #feda6b
}

.report-crash.dmg-257 .dmg__section-57 {
    fill: #ff5166
}

.report-crash.dmg-158 .dmg__section-58 {
    fill: #feda6b
}

.report-crash.dmg-258 .dmg__section-58 {
    fill: #ff5166
}

.report-crash.dmg-159 .dmg__section-59 {
    fill: #feda6b
}

.report-crash.dmg-259 .dmg__section-59 {
    fill: #ff5166
}

.report-crash.dmg-160 .dmg__section-60 {
    fill: #feda6b
}

.report-crash.dmg-260 .dmg__section-60 {
    fill: #ff5166
}

.report-crash.dmg-161 .dmg__section-61 {
    fill: #feda6b
}

.report-crash.dmg-261 .dmg__section-61 {
    fill: #ff5166
}

.report-crash.dmg-162 .dmg__section-62 {
    fill: #feda6b
}

.report-crash.dmg-262 .dmg__section-62 {
    fill: #ff5166
}

.report-crash.dmg-163 .dmg__section-63 {
    fill: #feda6b
}

.report-crash.dmg-263 .dmg__section-63 {
    fill: #ff5166
}

.report-crash.dmg-164 .dmg__section-64 {
    fill: #feda6b
}

.report-crash.dmg-264 .dmg__section-64 {
    fill: #ff5166
}

.report-crash.dmg-165 .dmg__section-65 {
    fill: #feda6b
}

.report-crash.dmg-265 .dmg__section-65 {
    fill: #ff5166
}

.report-crash.dmg-166 .dmg__section-66 {
    fill: #feda6b
}

.report-crash.dmg-266 .dmg__section-66 {
    fill: #ff5166
}

.report-crash.dmg-167 .dmg__section-67 {
    fill: #feda6b
}

.report-crash.dmg-267 .dmg__section-67 {
    fill: #ff5166
}

.report-crash.dmg-168 .dmg__section-68 {
    fill: #feda6b
}

.report-crash.dmg-268 .dmg__section-68 {
    fill: #ff5166
}

.report-crash.dmg-169 .dmg__section-69 {
    fill: #feda6b
}

.report-crash.dmg-269 .dmg__section-69 {
    fill: #ff5166
}

.report-crash.dmg-170 .dmg__section-70 {
    fill: #feda6b
}

.report-crash.dmg-270 .dmg__section-70 {
    fill: #ff5166
}

.report-crash.dmg-171 .dmg__section-71 {
    fill: #feda6b
}

.report-crash.dmg-271 .dmg__section-71 {
    fill: #ff5166
}

.report-crash.dmg-172 .dmg__section-72 {
    fill: #feda6b
}

.report-crash.dmg-272 .dmg__section-72 {
    fill: #ff5166
}

.report-crash.dmg-173 .dmg__section-73 {
    fill: #feda6b
}

.report-crash.dmg-273 .dmg__section-73 {
    fill: #ff5166
}

.report-crash.dmg-174 .dmg__section-74 {
    fill: #feda6b
}

.report-crash.dmg-274 .dmg__section-74 {
    fill: #ff5166
}

.report-crash.dmg-175 .dmg__section-75 {
    fill: #feda6b
}

.report-crash.dmg-275 .dmg__section-75 {
    fill: #ff5166
}

.report-crash.dmg-176 .dmg__section-76 {
    fill: #feda6b
}

.report-crash.dmg-276 .dmg__section-76 {
    fill: #ff5166
}

.report-crash.dmg-177 .dmg__section-77 {
    fill: #feda6b
}

.report-crash.dmg-277 .dmg__section-77 {
    fill: #ff5166
}

.report-crash.dmg-178 .dmg__section-78 {
    fill: #feda6b
}

.report-crash.dmg-278 .dmg__section-78 {
    fill: #ff5166
}

.report-crash.dmg-179 .dmg__section-79 {
    fill: #feda6b
}

.report-crash.dmg-279 .dmg__section-79 {
    fill: #ff5166
}

.report-crash.dmg-180 .dmg__section-80 {
    fill: #feda6b
}

.report-crash.dmg-280 .dmg__section-80 {
    fill: #ff5166
}

.report-crash.dmg-181 .dmg__section-81 {
    fill: #feda6b
}

.report-crash.dmg-281 .dmg__section-81 {
    fill: #ff5166
}

.report-crash.dmg-182 .dmg__section-82 {
    fill: #feda6b
}

.report-crash.dmg-282 .dmg__section-82 {
    fill: #ff5166
}

.report-crash.dmg-183 .dmg__section-83 {
    fill: #feda6b
}

.report-crash.dmg-283 .dmg__section-83 {
    fill: #ff5166
}

.report-crash.dmg-184 .dmg__section-84 {
    fill: #feda6b
}

.report-crash.dmg-284 .dmg__section-84 {
    fill: #ff5166
}

.report-crash.dmg-185 .dmg__section-85 {
    fill: #feda6b
}

.report-crash.dmg-285 .dmg__section-85 {
    fill: #ff5166
}

.report-crash.dmg-186 .dmg__section-86 {
    fill: #feda6b
}

.report-crash.dmg-286 .dmg__section-86 {
    fill: #ff5166
}

.report-crash.dmg-187 .dmg__section-87 {
    fill: #feda6b
}

.report-crash.dmg-287 .dmg__section-87 {
    fill: #ff5166
}

.report-crash.dmg-188 .dmg__section-88 {
    fill: #feda6b
}

.report-crash.dmg-288 .dmg__section-88 {
    fill: #ff5166
}

.report-crash.dmg-189 .dmg__section-89 {
    fill: #feda6b
}

.report-crash.dmg-289 .dmg__section-89 {
    fill: #ff5166
}

.report-crash.dmg-190 .dmg__section-90 {
    fill: #feda6b
}

.report-crash.dmg-290 .dmg__section-90 {
    fill: #ff5166
}

.report-crash.dmg-191 .dmg__section-91 {
    fill: #feda6b
}

.report-crash.dmg-291 .dmg__section-91 {
    fill: #ff5166
}

.report-crash.dmg-192 .dmg__section-92 {
    fill: #feda6b
}

.report-crash.dmg-292 .dmg__section-92 {
    fill: #ff5166
}

.report-crash.dmg-193 .dmg__section-93 {
    fill: #feda6b
}

.report-crash.dmg-293 .dmg__section-93 {
    fill: #ff5166
}

.report-crash.dmg-194 .dmg__section-94 {
    fill: #feda6b
}
.report-crash.dmg-294 .dmg__section-94 {
    fill: #ff5166
}

.report-crash.dmg-195 .dmg__section-95 {
    fill: #feda6b
}

.report-crash.dmg-295 .dmg__section-95 {
    fill: #ff5166
}

.report-crash.dmg-196 .dmg__section-96 {
    fill: #feda6b
}

.report-crash.dmg-296 .dmg__section-96 {
    fill: #ff5166
}

.report-crash.dmg-197 .dmg__section-97 {
    fill: #feda6b
}

.report-crash.dmg-297 .dmg__section-97 {
    fill: #ff5166
}

.report-crash.dmg-198 .dmg__section-98 {
    fill: #feda6b
}

.report-crash.dmg-298 .dmg__section-98 {
    fill: #ff5166
}

.report-crash.dmg-199 .dmg__section-99 {
    fill: #feda6b
}

.report-crash.dmg-299 .dmg__section-99 {
    fill: #ff5166
}

.report-crash.dmg-1 .dmg__section-1, .report-crash.dmg-2 .dmg__section-2, .report-crash.dmg-3 .dmg__section-3, .report-crash.dmg-4 .dmg__section-4, .report-crash.dmg-5 .dmg__section-5, .report-crash.dmg-6 .dmg__section-6,
.report-crash.dmg-7 .dmg__section-7, .report-crash.dmg-8 .dmg__section-8, .report-crash.dmg-9 .dmg__section-9 {
    fill: #51a5ff
}

[data-v-7a1233dc]:root {
    --grid-gutter: 0.8rem
}

.cls-bottom[data-v-7a1233dc] {
    fill: #000
}

.cls-10[data-v-7a1233dc], .cls-2[data-v-7a1233dc], .cls-3[data-v-7a1233dc], .cls-4[data-v-7a1233dc], .cls-5[data-v-7a1233dc], .cls-8[data-v-7a1233dc], .cls-9[data-v-7a1233dc] {
    stroke: #000;
    stroke-miterlimit: 10
}

.cls-2[data-v-7a1233dc], .cls-3[data-v-7a1233dc], .cls-4[data-v-7a1233dc], .cls-5[data-v-7a1233dc] {
    stroke-width: 1.3px
}

.cls-13[data-v-7a1233dc] {
    stroke: #000;
    stroke-width: .75px
}

.cls-10[data-v-7a1233dc], .cls-8[data-v-7a1233dc], .cls-9[data-v-7a1233dc] {
    fill: none
}

.cls-8[data-v-7a1233dc] {
    stroke-width: .75px
}

.cls-9[data-v-7a1233dc] {
    stroke-width: .5px
}

.cls-10[data-v-7a1233dc] {
    stroke-width: .25px
}

.cls-12[data-v-7a1233dc] {
    fill: #fff
}

/*Legend Styles*/
[data-v-527ae0f9]:root {
    --grid-gutter: 0.8rem
}



.legend {
    margin-left: -20px;
}

.legend__item--default {
    position: relative;
    margin-left: 0px;
}

.legend__item--warning {
    position: relative;
    margin-left: 0px;
    margin-top: 10px;
}

.legend__item--danger {
    position: relative;
    margin-left: 0px;
    margin-top: 10px;
}

.legend__item--unknown {
    position: relative;
    margin-left: 0px;
    margin-top: 10px;
}

.legend__value {
    margin-left: 10px;
    font-family: Ysabeau Infant,sans-serif !important;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700 !important;
}

.base-indicator[data-v-527ae0f9] {
    width: 1.4rem;
    height: 1.4rem;
    background-color: #fff;
    border: .1rem solid #d8d8d8;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: -20px;
}

.base-indicator--warning[data-v-527ae0f9] {
    background-color: #feda6b
}

.base-indicator--danger[data-v-527ae0f9] {
    background-color: #ff5166
}

.base-indicator--unknown[data-v-527ae0f9] {
    background-color: #51a5ff
}

.base-indicator--active[data-v-527ae0f9] {
    background-color: #067eff
}

.base-indicator--disable[data-v-527ae0f9] {
    background-color: #b6b6b7
}

/*Generic car styles*/
[data-v-6585ac62]:root {
 --grid-gutter:0.8rem
}
.cls-1[data-v-6585ac62],
.cls-2[data-v-6585ac62],
.cls-3[data-v-6585ac62],
.cls-5[data-v-6585ac62],
.cls-6[data-v-6585ac62],
.cls-7[data-v-6585ac62],
.cls-8[data-v-6585ac62] {
 stroke:#000;
 stroke-miterlimit:10
}
.cls-1[data-v-6585ac62],
.cls-2[data-v-6585ac62],
.cls-7[data-v-6585ac62],
.cls-8[data-v-6585ac62] {
 stroke-width:1.3px
}
.cls-3[data-v-6585ac62],
.cls-5[data-v-6585ac62],
.cls-6[data-v-6585ac62] {
 fill:none;
}
.cls-3[data-v-6585ac62] {
 stroke-width:.75px
}
.cls-4[data-v-6585ac62] {
 fill:#fff
}
.cls-5[data-v-6585ac62] {
 stroke-width:.5px
}
.cls-6[data-v-6585ac62] {
 stroke-width:.25px
}

/*Track styles*/
[data-v-f2009c9c]:root {
 --grid-gutter:0.8rem
}
.cls-0[data-v-f2009c9c] {
 fill:#000
}
.cls-1[data-v-f2009c9c],
.cls-10[data-v-f2009c9c],
.cls-11[data-v-f2009c9c],
.cls-2[data-v-f2009c9c],
.cls-3[data-v-f2009c9c],
.cls-4[data-v-f2009c9c],
.cls-5[data-v-f2009c9c],
.cls-6[data-v-f2009c9c],
.cls-7[data-v-f2009c9c],
.cls-8[data-v-f2009c9c],
.cls-9[data-v-f2009c9c] {
 fill:#fff;
 stroke:#000;
 stroke-miterlimit:10
}
.cls-1[data-v-f2009c9c],
.cls-10[data-v-f2009c9c],
.cls-2[data-v-f2009c9c],
.cls-3[data-v-f2009c9c],
.cls-4[data-v-f2009c9c],
.cls-7[data-v-f2009c9c],
.cls-8[data-v-f2009c9c] {
 stroke-width:1.3px;
}
.cls-2[data-v-f2009c9c],
.cls-4[data-v-f2009c9c] {
 fill:#fff
}
.cls-11[data-v-f2009c9c],
.cls-5[data-v-f2009c9c],
.cls-8[data-v-f2009c9c],
.cls-9[data-v-f2009c9c] {
 fill:none
}
.cls-5[data-v-f2009c9c],
.cls-6[data-v-f2009c9c] {
 stroke-width:.5px
}
.cls-9[data-v-f2009c9c] {
 stroke-width:.75px
}
.cls-11[data-v-f2009c9c] {
 stroke-linecap:round;
 stroke-width:3px
}
.cls-12[data-v-f2009c9c] {
 fill:#000
}
.cls-13[data-v-f2009c9c] {
 fill:#fff;
}
.st0[data-v-f2009c9c] {
 fill:none;
 stroke:#000;
 stroke-miterlimit:10;
 stroke-width:1.3px
}
.st4[data-v-f2009c9c] {
 fill:none;
 stroke:#000;
 stroke-miterlimit:10;
 stroke-width:.5px
}
.st7[data-v-f2009c9c] {
 fill:none;
 stroke:#000;
 stroke-miterlimit:10;
 stroke-width:1.3px
}
.st8[data-v-f2009c9c] {
 fill:none;
 stroke:#000;
 stroke-miterlimit:10;
 stroke-width:.75px
}
.st10[data-v-f2009c9c] {
 fill:none;
 stroke:#000;
 stroke-linecap:round;
 stroke-miterlimit:10;
 stroke-width:3px
}

/*Autobus styles*/
[data-v-60ac9ed0]:root {
 --grid-gutter:0.8rem
}
.cls-1[data-v-60ac9ed0],
.cls-2[data-v-60ac9ed0],
.cls-3[data-v-60ac9ed0] {
 fill:#fff
}
.cls-1[data-v-60ac9ed0],
.cls-2[data-v-60ac9ed0],
.cls-3[data-v-60ac9ed0],
.cls-4[data-v-60ac9ed0],
.cls-5[data-v-60ac9ed0],
.cls-6[data-v-60ac9ed0],
.cls-8[data-v-60ac9ed0] {
 stroke:#000;
 stroke-miterlimit:10
}
.cls-1[data-v-60ac9ed0],
.cls-2[data-v-60ac9ed0],
.cls-3[data-v-60ac9ed0],
.cls-4[data-v-60ac9ed0] {
 stroke-width:1.3px
}
.cls-4[data-v-60ac9ed0],
.cls-5[data-v-60ac9ed0],
.cls-6[data-v-60ac9ed0],
.cls-7[data-v-60ac9ed0],
.cls-8[data-v-60ac9ed0] {
 fill:none
}
.cls-5[data-v-60ac9ed0] {
 stroke-width:.75px
}
.cls-6[data-v-60ac9ed0] {
 stroke-width:.5px
}
.cls-8[data-v-60ac9ed0] {
 stroke-linecap:round;
 stroke-width:3px
}
.cls-9[data-v-60ac9ed0] {
 fill:#000
}

/*Motorhome style*/
[data-v-136f7dbe]:root {
 --grid-gutter:0.8rem
}
.cls-1[data-v-136f7dbe],
.cls-2[data-v-136f7dbe] {
 fill:#fff
}
.cls-1[data-v-136f7dbe],
.cls-2[data-v-136f7dbe],
.cls-3[data-v-136f7dbe],
.cls-4[data-v-136f7dbe],
.cls-5[data-v-136f7dbe],
.cls-7[data-v-136f7dbe] {
 stroke:#000;
 stroke-miterlimit:10
}
.cls-1[data-v-136f7dbe],
.cls-2[data-v-136f7dbe],
.cls-3[data-v-136f7dbe] {
 stroke-width:1.3px
}
.cls-3[data-v-136f7dbe],
.cls-4[data-v-136f7dbe],
.cls-5[data-v-136f7dbe],
.cls-6[data-v-136f7dbe],
.cls-7[data-v-136f7dbe] {
 fill:none
}
.cls-7[data-v-136f7dbe] {
 stroke-linecap:round;
 stroke-width:3px
}
.st0[data-v-136f7dbe] {
 fill:#fff
}
.st0[data-v-136f7dbe],
.st2[data-v-136f7dbe] {
 stroke:#000;
 stroke-miterlimit:10;
 stroke-width:1.3px
}
.st2[data-v-136f7dbe] {
 fill:none
}
.st3[data-v-136f7dbe] {
 fill:none;
 stroke:#000;
 stroke-miterlimit:10;
 stroke-width:.75px
}
.st4[data-v-136f7dbe] {
 stroke:#000;
 stroke-miterlimit:10;
 stroke-width:.5px
}
.st4[data-v-136f7dbe],
.st5[data-v-136f7dbe] {
 fill:none
}
.st6[data-v-136f7dbe] {
 fill:none;
 stroke:#000;
 stroke-linecap:round;
 stroke-miterlimit:10;
 stroke-width:3px
}
.st7[data-v-136f7dbe] {
 fill:none;
 stroke:#000;
 stroke-linecap:round;
 stroke-miterlimit:10;
 stroke-width:3
}



