.tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.general-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.general-info__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.general-info__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
  width: 100%;
}

.general-info__check {
  flex-shrink: 0;
}

.tab__heading-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

@media (max-width: 1100px) {
  .general-info__list {
    gap: 14px;
  }
}
