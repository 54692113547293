.short-report__content{
  padding: 60px 0 100px;
}

.short-report__title {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
}

.short-report__title-text {
  margin: 0;
  font-size: 18px;
  line-height: 140%;
}

.short-report__wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
}

.report-block {
  width: 47%;
  display: flex;
  flex-direction: column;
}

.report-block__title {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.report-block__text {
  margin: 0;
  margin-bottom: 20px;
}

.report-block__info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (max-width: 1100px) {
  .short-report__content{
    padding: 46px 0 60px;
  }  
}

@media (max-width: 940px) {
  .short-report__wrap {
    justify-content: center;
    gap: 25px;
  }
}

@media (max-width: 800px)  {
  .short-report__wrap {
    flex-direction: column;
    gap: 35px;
  }

  .report-block {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .report-block__title {
    align-items: flex-start;
  }

  .report-block__title img {
    margin-top: 5px;
  }
}