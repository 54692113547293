.admin-data-title {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 24px!important;
    margin-top: 15px!important;
    margin-bottom: 15px!important;
}

.add-admin-data-btn {
    background: #498EDF!important;
    color: white!important;
    margin-left: 15px!important;
    margin-bottom: 10px!important;
}