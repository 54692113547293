.card-container {
    height: 450px;
    user-select: none;
    text-align: center;
    width: 290px;
    box-shadow: 0px 0 6px 4px rgba(0, 0, 0, 0.158);
    border-radius: 7px!important;
    background-color: #eeeeee;
    margin: 0 auto!important;
    margin-top: 25px!important;
}

.card-header {
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    height: 80px;
}

.card-header-title {
    color: white;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 34px!important;
}

.custom-discount-badge {
    background: #D9D9D9;
    text-align: center;
    padding: 2px;
    width: 50px;
    height: 25px;
    border-radius: 7px;
    margin-left: auto!important;
    margin-bottom: -4px!important;
    position: relative;
    top: 5px;
    margin-right: 5px!important;
}

.custom-discount-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 16px!important;
}

.price-for-one {
    color: black;
    font-family: 'Ysabeau Infant', sans-serif!important;
    text-align: center;
    font-weight: 600!important;
    font-size: 16px!important;
}

.price-plan {
    color: black;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 34px!important;
    margin-top: -10px!important;
}

.buy-plan-button {
    margin: 0 auto!important;
    user-select: none;
    cursor: pointer;
    text-align: center;
    width: 180px;
    height: 45px;
    background: #498EDF!important;
    border-radius: 7px;
    margin-top: 40px!important;
}

.buy-plan-button:hover {
    background: #277FE4!important;
}

.buy-plan-button-text  {
    color: white!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;    
    font-size: 22px!important;
    position: relative;
    top: 3px;
}

.plan-card-image {
    margin-top: 20%;
    width: 200px;
}

.header-title-no-discount {
    padding-top: 23px;
}


@media (max-width: 1536px) {
    .card-container {
        height: 400px;
        width: 230px;
    }
    .buy-plan-button {
        margin-top: 20px!important;
    }
}

@media (max-width: 1199px) {
    .card-container {
        height: 400px;
        width: 230px;
    }
}

@media (max-width: 550px) {
    .card-container {
        height: 340px;
        width: 200px;
    }
    .buy-plan-button {
        width: 160px;
        height: 40px;
        margin-top: 19px!important;
    }
    .buy-plan-button-text {
        font-size: 20px!important;
    }
    .price-for-one {
        color: black;
        font-family: 'Ysabeau Infant', sans-serif!important;
        margin-right: 45px!important;
        font-weight: 600!important;
        font-size: 16px!important;
        margin-top: 20px!important;
    }
    
    .price-plan {
        font-size: 32px!important;
    }
    .card-header-title {
        font-size: 28px!important;
    }
    .plan-card-image {
        margin-top: 15px;
        width: 140px;
    }
    
}

@media (max-width: 450px) {
    .card-container {
        height: 260px;
        width: 160px;
    }
    .buy-plan-button  {
        width: 80px;
        height: 35px;
        margin-top: 15px!important;
    }
    .buy-plan-button-text {
        font-size: 18px!important;
    }
    .card-header {
        height: 60px;
    }
    .card-header-title {
        font-size: 22px!important;
    }
    .custom-discount-badge {
        width: 35px;
        height: 20px;
    }
    .custom-discount-text  {
        font-size: 12px!important;
    }
    .price-for-one {
        margin-right: 5px!important;
        font-size: 14px!important;
        margin-top: 0px!important;
    }
    .price-plan {
        font-size: 28px!important;
    }
    .plan-card-image {
        margin-top: 10px;
        width: 120px;
    }
}