html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  --main-color: #004CDB;
  width: 100%;
  margin: 0;
  padding-top: 30px;
  min-width: 320px;
  min-height: 100%;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 140%;
  color: #000000;
  background-color: #F8FAFF;
  overflow-x: hidden;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

a {
  text-decoration: none;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
}

button,
input,
input::placeholder {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.hidden {
  display: none;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.section-heading {
  margin: 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
}

.heading-three {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

@keyframes light {
  0% {
    transform: translate(-100%, -25%) rotate(10deg);
  }

  10% {
    transform: translate(100%, -25%) rotate(10deg);
  }

  100% {
    transform: translate(100%, -25%) rotate(10deg);
  }
}

.main-button {
  position: relative;
  background: var(--main-color);
  transition: all 0.3s ease;
  overflow: hidden;
}

.main-button::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 200%;
  filter: blur(8px);
  background: linear-gradient(to left, transparent 0%, #fff 40%, #fff 60%, transparent 100%);
  transform: translate(-100%, -25%) rotate(10deg);
}

.main-button:focus,
.main-button:hover {
  outline: none;
}

.main-button:focus::before,
.main-button:hover::before {
  opacity: 0.7;
  animation: light 3s;
}

.main-button:active {
  opacity: .7;
}

.second-button {
  transition: all 0.3s ease;
}

.second-button:focus,
.second-button:hover {
  outline: none;
  box-shadow: 0px 2px 5px rgba(21, 97, 238, 0.5);
}

.second-button:active {
  opacity: .7;
}

@media (max-width: 1240px) {
  .container {
    width: 1100px;
    padding: 0 10px;
  }
}

@media (max-width: 1140px) {
  .container {
    width: 1060px;
  }
}

@media (max-width: 1100px) {
  .container {
    width: 920px;
  }

  .section-heading {
    font-size: 28px;
  }

  .heading-three {
    font-size: 18px;
  }
}

@media (max-width: 940px) {
  .container {
    width: 860px;
  }
}

@media (max-width: 880px) {
  .container {
    width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 800px) {
  .section-heading {
    font-size: 24px;
  }
}

@media (max-width: 660px) {}

@media (max-width: 560px) {}

@media (max-width: 480px) {
  .section-heading {
    font-size: 22px;
  }
}

@media (max-width: 420px) {}