.auth-form {
  width: 100%;
}

.auth-form__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.auth-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
}

.auth-fields__wrap {
  width: 100%;
  margin: 0;
}

.auth-field {
  width: 100%;
  margin-top: 0;
  padding: 16px 30px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.auth-field:focus {
  outline: none;
  border: 1px solid var(--main-color);
}

.auth-field--error {
  border: 1px solid #FF0000;
}

.auth-field__message-error {
  color: #FF0000;
}

.auth-form__buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.auth-form__button {
  padding: 16px 32px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #FFFFFF;
  background: var(--main-color);
  border-radius: 10px;
}

.auth-form__button--recovery,
.auth-form__button--help {
  padding: 16px 50px;
}

.auth-form__button--help {
  width: 179.5px;
}

.auth-form__link {
  width: 48px;
  height: 48px;
  border: 0.6px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

.auth-form__link--yandex {
  background-image: url("../../../media/images/new/yandex.svg");
}

.auth-form__link--google {
  background-image: url("../../../media/images/new/google.svg");
}

.auth-form__link--vk {
  background-image: url("../../../media/images/new/vk.svg");
}

.auth-field__input-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-fields__change-input-type {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 14px;
  background-image: url('../../../media/images/new/show-password.png');
  background-repeat: no-repeat;
  background-size: 100% 200%;
  background-position: 0 0;
  opacity: .5;
}

.auth-fields__change-input-type--show {
  background-position: 0 100%;
}

@media (max-width: 1100px)  {
  .auth-field {
    padding: 13px 20px;
  }

  .auth-form__buttons {
    justify-content: space-between;
    gap: 10px;
  }

  .auth-form__button {
    padding: 16px 20px;
  }
}

@media (max-width: 940px)  {
  .auth-form__container {
    gap: 20px;
  }
}

@media (max-width: 880px) {
  .auth-form__link  {
    width: 44px;
    height: 44px;
  }
}
@media (max-width: 420px) {
  .auth-form__button {
    padding: 14px 10px;
  }
}