.tab-wrap {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.profile {
  width: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.profile__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.profile__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
}

.profile__subtitle {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.profile__avatar-wrap {
  width: 100px;
  height: 100px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  overflow: hidden;
}

.profile__avatar-change {
  position: relative;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  overflow: hidden;
}

.profile__avatar-change img {
  filter: blur(2px);
}

.profile__avatar-change:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  background-image: url("../../../../media/images/new/icon-avatar.svg");
  background-repeat: no-repeat;
  background-size: 60px 60px;
  background-position: center;
  z-index: 1;
  pointer-events: none;
  border-radius: 50%;
}

.profile__avatar-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.profile__wrap {
  width: 100%;
  display: flex;
  gap: 15px;
}

.profile__input-wrap {
  width: calc((100% - 2 * 15px) / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
}

.profile__label {
  opacity: 0.5;
}

.profile__input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  gap: 9px;
  font-weight: 300;
  font-size: 15px;
  line-height: 140%;
  color: #000000;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.profile__input:focus {
  outline: none;
  border: 1px solid var(--main-color);
}

.profile__input--error {
  border: 1px solid #FF0000;
}

.profile__input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.profile__buttons {
  display: flex;
  gap: 15px;
}

.profile__button {
  width: 179.5px;
  padding: 14px 28px;
  border-radius: 10px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-size: 15px;
  line-height: 120%;
}

.profile__button--submit {
  font-weight: 600;
  color: #FFFFFF;
  background: #004CDB;
}

.profile__button--logout {
  font-weight: 400;
  color: #FF0000;
  background: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
}

.profile__message-error {
  color: #FF0000;
}

.request-archive {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 800px) {
  .profile {
    border-radius: 10px;
  }
  .profile__wrap {    
    gap: 10px;
  }

  .profile__wrap--user-data,
  .profile__wrap--change-password {
    flex-direction: column;
  }

  .profile__input-wrap {
    width: 100%;
    gap: 10px;
  }
}

@media (max-width: 560px) {
  .profile__buttons {
    flex-direction: column;
  }
}