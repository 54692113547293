.short-block {
  padding: 30px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
  border-radius: 20px;
}

.short-legal-risks__item {
  position: relative;
  padding: 10px 10px 10px 20px;
  font-weight: bold;
}

.short-legal-risks__item:not(:last-child) {
  border-bottom: 1px solid rgba(218, 215, 215, 0.5);
}

.short-legal-risks__item p {
  font-weight: 400;
  margin: 0;
  margin-top: 10px;
}

.short-legal-risks__item::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: rgb(75, 238, 75);
}

.short-legal-risks__item--error::before {
  background-color: rgb(253, 52, 52);
  top: 14%;
  transform: translateY(0%);
}

@media (max-width: 1100px) {
  .short-block {
    padding: 20px;
  }
}
