.slider {
  width: 710px;
  position: relative;
}

.slider__wrapper {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0 auto;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 20px;
  gap: 40px;
  isolation: isolate;

  background: #F8FAFF;
  border: 3px solid rgba(0, 76, 219, 0.1);
  box-shadow: 0px 4px 30px rgba(0, 76, 219, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 30px;
}

.slider__list {
  display: flex;
  align-items: center;
  position: relative;
  gap: 50px;
  transform: translateX(-50%);
  left: 50%;
  margin: 0 auto;
  overflow: hidden;
}

.slide {
  /* width: 323px;
  height: 217px; */
  width: 462px;
  height: 100%;
  margin: calc(0px - (315px - 217px) / 2) calc(0px - (462px - 323px) / 2);

  transform: scale(0.8);
  /* border: 1px solid orange; */
}

.slide--central {
 margin: 0;
  /* width: 462px;
  height: 315px; */
  transform: scale(1);
}

.slide__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide__info {
  position: relative;
  top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 10px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3.64px 18.2px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(18.2px);
  border-radius: 20px;
}

.slide__info-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.slide__info-user {
  display: flex;
  flex-direction: column;
}

.slide__info-user-name,
.slide__info-profession,
.slide__info-note {
  margin: 0;
}

.slide__info-user-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.slide__info-profession {
  font-size: 12px;
  opacity: 0.5;
}

.slide__info-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.slide__info-stars {
  display: flex;
}

.slide__info-note {
  font-size: 12px;
  opacity: 0.5;
}

.slide__info-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.slide__info-heading {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
}

.slide__info-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.slide__info-text {
  font-size: 12px;
  line-height: 140%;
}

@media (max-width: 1240px) {
  .slider {
    width: calc((100% - 30px - 450px));
  }
}

@media (max-width: 1140px) {
  .slider {
    width: calc((100% - 20px - 430px));
  }
}

@media (max-width: 1100px) {
  .slider {
    width: calc((100% - 20px - 400px));
  }

  .slide {
    width: 370px;
  }
}


@media (max-width: 940px) {
  .slide {
    width: 320px;
  }
}

@media (max-width: 880px) {
  .slider {
    width: calc((100% - 20px - 370px));
  }
}

@media (max-width: 800px) {
  .slider {
    display: none;
  }
}