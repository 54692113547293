.report-container {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border-bottom: 6px solid #498EDF!important;
}

.text-item-report {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
}
.report-characteristics-block {
    margin-left: auto!important;
    width: 60%;
}
.characteristics-item-report {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    text-align: left;
}

.report-text-bold {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}


.report-preview-swiper {
    display: flex;
    align-items: center;
    text-align: center;
    height: 300px;
}

.report-preview-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.report-preview-image {
    user-select: none;
    cursor: pointer;
    height: 95%;
    border-radius: 15px!important;
    border: 6px solid #8C8C8C;
}


.report-brand-logo {
    width: 100px;
}

.report-model-name {
    font-size: 24px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin-left: 15px!important;
}

.report-status-success {    
    color: #498EDF;
    font-weight: 700!important;
}
.report-status-progress {
    color: #82DF49;
    font-weight: 700!important;
}

.get-full-report-btn {
    background: #498EDF!important;
    color: #fff!important;
    width: 250px;
    height: 50px;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 16px!important;
    margin: 25px auto!important;
}

.report-container-title {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 32px!important;
}

.top-error-report {
    background: #E4E4E4!important;
    border-radius: 50%;
    font-size: 32px!important;
    border: 1px solid black;
    position: fixed;
    bottom: 20px; /* или любое другое значение, которое вы предпочитаете */
    right: 20px; 
    cursor: pointer;
}

@media (max-width: 899px) {
    .characteristics-item-report {
        text-align: left;
        display: flex;
        justify-content: center;
    }
    .report-characteristics-block {
        margin: 0 auto!important;
        text-align: left;
        width: 100%;
    }
    .report-preview-image {
        border-radius: 15px!important;
        border: 6px solid #8C8C8C;
        height: 70%;
    }
    .characteristics-grid {
        margin-top: -90px!important;
        margin-bottom: 10px!important;
    }
    .report-model-name {
        font-size: 20px!important;
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 700!important;
        margin-left: 5px!important;
    }
    .report-text-bold {
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 700!important;
        margin-left: 5px!important;
    }    
    .report-brand-logo {
    }    
    .report-brand-name-x {
        display: none;
    }
    .characteristics-grid {
        margin-top: -70px!important;
        margin-bottom: 15px!important;
    }
}

@media (max-width: 600px) {
    .report-container-title {
        font-family: 'Ysabeau Infant', sans-serif!important;
        font-weight: 700!important;
        font-size: 24px!important;
    }
    
}