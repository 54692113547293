.modal-details-damage__header-form {
  position: relative;
  background: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.modal-details-damage__title {
  font-family: 'Myriad Pro' !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  color: white !important;
}

.modal-details-damage__close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white !important;
  cursor: pointer;
}

.modal-details-damage__container {
  width: 450px;
  background: #fff;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 15px;
}

@media (max-width: 560px) {
  .modal-details-damage__container {
    width: 400px;
  }
}

@media (max-width: 420px) {
  .modal-details-damage__container {
    width: 360px;
  }
}