.btn--up {
  position: fixed;
  display: none;
  width: 50px;
  height: 50px;
  bottom: 45px;
  right: 45px;
  background: rgba(0, 76, 219, 0.1);
  border: 2px solid var(--main-color);
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  opacity: 0.7;
  transition: all 0.5s ease-out;
  cursor: pointer;
  z-index: 1;
}

.arrow-top {
  margin-top: 5px;
  width: 25px;
  height: 25px;
  transform: rotate(270deg);
}

.arrow-top path {
  fill: var(--main-color);
}

.btn--up:focus,
.btn--up:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-out;
  opacity: 1;
}

.shown {
  display: block;
}

@media (max-width: 800px) {
  .btn--up {
    right: 25px;
  }
}

@media (max-width: 560px) {
  .btn--up {
    width: 40px;
    height: 40px;
  }

  .arrow-top {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .btn--up {
    bottom: 26px;
  }
}
