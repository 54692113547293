.design-btn-change {
    background: #498EDF!important;
    color: white!important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important; 
    width: 300px;
    margin: 0 auto!important;
}

.design-logo-demo {
    max-width: 200px;
    text-align: center;
}