.privacy {
  padding: 60px 0;
}

.privacy__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.section-heading--privacy {
  margin-left: 30px;
  color: var(--main-color);
}

.privacy__text {
  margin: 0;
  margin-left: 30px;
  margin-top: -15px;
}

.privacy__list {
  margin-top: 0;
  padding: 0 30px 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  counter-reset: item;
}

.privacy__inner-list {
  counter-reset: item;
  padding-left: 10px;
}

.privacy__item,
.privacy__inner-item {
  display: block
}

.privacy__item:before,
.privacy__inner-item:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.privacy__item:before {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.heading-three--privacy {
  display: inline-block;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.privacy__unordered-list {
  list-style: none;
}

.privacy__unordered-list li {
  position: relative;
}

.privacy__unordered-list li::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  background-color: #000;
}

