.short-sales-history {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.short-sales-history__title {
  margin: 0;
}

.short-sales-history__images-wrap {
  width: 446px;
  height: 100px;
  display: flex;
  gap: 2px;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid green;
}

.short-sales-history__all-images {
  position: relative;
}

.short-sales-history__all-images img {
  height: 100%;
}

.short-sales-history__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: rgba(70, 66, 66, 0.7);
}

.short-sales-history__more {
  color: #fff;
  font-size: 14px;
}

.short-sales-history__text {
  margin: 0;
  font-weight: 400;
}

.short-sales-history__text span {
  color: rgb(27, 143, 238);
}

.short-sales-history__info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.short-sales-history__parameter {
  width: calc((100% - 15px) /2);
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.short-sales-history__parameter span {
  color: #000000;
}

.short-sales-history__decrease {
  font-size: 14px;
  color: rgb(41, 189, 36) !important;
}

@media (max-width: 1240px) {
  .short-sales-history__images-wrap {
    width: 100%;
  }

  .short-sales-history__images-wrap img {
    width: calc((100% - 3 * 2px) / 4);
  }

  .short-sales-history__all-images {
    width: calc((100% - 3 * 2px) / 4);
  }

  .short-sales-history__all-images img {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .short-sales-history {
    padding: 20px;
  }
}
