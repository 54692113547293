
.button--summary-up {
  display: none;
  position: fixed;
  padding: 14px 20px;
  bottom: 45px;
  right: 100px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  border-radius: 10px;
  color: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 76, 219, 0.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 800px) {
  .button--summary-up {
    display: block;
  }
}

@media (max-width: 560px) {
  .button--summary-up {
    padding: 10px 16px;
    right: 80px;
  }
}

@media (max-width: 480px) {  
  .button--summary-up {
    bottom: 26px;
  }
}

.hidden {
  display: none;
}
