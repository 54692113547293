.auth {
  padding: 100px 0;
}

.auth__container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.auth__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 37px;
  gap: 30px;
  width: 450px;
  background: #FFFFFF;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 30px;
}

.auth__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  width: 100%;
}

.section-heading--auth {
  font-weight: 600;
}

.auth__text {
  margin: 0;
}

.auth__info-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.auth__link {
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: var(--main-color);
}

.auth__agreement,
.auth__agreement-link {
  font-size: 12px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.8;
}

.auth__agreement {
  margin: 0;
}

.auth__agreement-link {
  text-decoration: underline;
}

@media (max-width: 1140px) {
  .auth__wrap {
    width: 430px;
    padding: 25px;
  }

  .auth__container {
    gap: 20px;
  }
}

@media (max-width: 1100px) {
  .auth__wrap {
    width: 400px;
  }
}

@media (max-width: 940px) {
  .auth__wrap {
    gap: 20px;
  }
}

@media (max-width: 880px) {
  .auth {
    padding: 40px 0 60px;
  }

  .auth__wrap {
    width: 370px;
    padding: 20px;
  }

  .auth__info-block {
    gap: 6px;
  }

  .section-heading--auth {
    font-size: 24px;
  }
}

@media (max-width: 800px) {
  .auth__container {
    justify-content: center;
  }

  .auth__wrap {
    width: 450px;
  }
}

@media (max-width: 560px) {
  .auth__wrap {
    width: 400px;
  }
}

@media (max-width: 480px) {
  .auth__wrap {
    width: 100%;
  }
}