.arbitration {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.arbitration__title {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arbitration__count {
  min-width: 234px;
  padding: 14px 20px;
  font-weight: 400;
  border-radius: 10px;
  backdrop-filter: blur(20px);
}

.arbitration__count--error {
  background: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #FF0000;
}

.arbitration__count--success {
  background: rgba(0, 227, 0, 0.05);
  border: 2px solid rgba(0, 227, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #00E300;
}

.arbitration__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.arbitration__item {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.arbitration__parameter {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.arbitration__parameter span {
  color: #000000;
}

.arbitration__link {
  position: relative;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: var(--main-color);
}

.arbitration__link::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2px;
  right: -20px;
  background-image: url("../../../../media/images/new/icon-download.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
}

@media (max-width: 880px) {
  .arbitration__list {
    gap: 16px;
  }

  .arbitration__item {
    padding: 20px;
    gap: 8px;
    border-radius: 10px;
  }
}

@media (max-width: 660px) {
  .arbitration__title {
    padding: 20px 0;
  }

  .arbitration__count {
    min-width: unset;
  }
}

@media (max-width: 420px) {
  .arbitration__title {
    flex-direction: column;
    gap: 18px;
    padding-bottom: 10px;
  }

  .arbitration__count {
    padding: 10px 20px;
  }
}