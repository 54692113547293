.answers {
  padding: 60px 0;
  background: #F8FAFF;
}

.section-heading--answers {
  margin-bottom: 40px;
}

.answers__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.answers__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 22px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  cursor: pointer;
}

.answers__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.answers__question {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.answers__answer {
  margin: 0;
  display: none;
}

.answers__button {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-image: url("../../../media/images/new/add_circle.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  transition: all 0.3s ease;
}

.answers__button--read-less {
  transform: rotate(135deg);
  display: none;
}

#answers-object-1:checked~.answers__list .answers__item--1,
#answers-object-2:checked~.answers__list .answers__item--2,
#answers-object-3:checked~.answers__list .answers__item--3,
#answers-object-4:checked~.answers__list .answers__item--4,
#answers-object-5:checked~.answers__list .answers__item--5,
#answers-object-6:checked~.answers__list .answers__item--6 {
  cursor: unset;
}

#answers-object-1:checked~.answers__list .answers__item--1 .answers__answer,
#answers-object-2:checked~.answers__list .answers__item--2 .answers__answer,
#answers-object-3:checked~.answers__list .answers__item--3 .answers__answer,
#answers-object-4:checked~.answers__list .answers__item--4 .answers__answer,
#answers-object-5:checked~.answers__list .answers__item--5 .answers__answer,
#answers-object-6:checked~.answers__list .answers__item--6 .answers__answer {
    display: block;    
  }

#answers-object-1:checked~.answers__list .answers__item--1 .answers__button--read-more,
#answers-object-2:checked~.answers__list .answers__item--2 .answers__button--read-more,
#answers-object-3:checked~.answers__list .answers__item--3 .answers__button--read-more,
#answers-object-4:checked~.answers__list .answers__item--4 .answers__button--read-more,
#answers-object-5:checked~.answers__list .answers__item--5 .answers__button--read-more,
#answers-object-6:checked~.answers__list .answers__item--6 .answers__button--read-more {
    display: none;
  }

#answers-object-1:checked~.answers__list .answers__item--1 .answers__button--read-less,
#answers-object-2:checked~.answers__list .answers__item--2 .answers__button--read-less,
#answers-object-3:checked~.answers__list .answers__item--3 .answers__button--read-less,
#answers-object-4:checked~.answers__list .answers__item--4 .answers__button--read-less,
#answers-object-5:checked~.answers__list .answers__item--5 .answers__button--read-less,
#answers-object-6:checked~.answers__list .answers__item--6 .answers__button--read-less {
    display: block;
  }

  .answers__inner-list {
    margin-top: 10px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .answers__inner-list li {
    position: relative;
  }

  .answers__inner-list li::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 3px;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    background-color: var(--main-color);
    border-radius: 50%;
  }

  @media (max-width: 1100px) {
    .answers {
      padding: 46px 0;
    }

    .section-heading--answers {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 800px) {
    .answers__button {
      width: 25px;
      height: 25px;
    }
  }