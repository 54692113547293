.photos-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.photos__title {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photos__count {
  width: 194px;
  padding: 14px 34px;
  font-weight: 400;
  color: var(--main-color);
  background: rgba(0, 76, 219, 0.1);
  border: 2px solid rgba(0, 76, 219, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.photos__block {
  width: 100%;
  display: flex;
  gap: 20px;
}

.photos__wrap {
  width: calc((100% - 3 * 20px) / 4);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
}

.photos__wrap--last {
  position: relative;
}

.photos__photo {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.photos__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2.5px);
  cursor: pointer;
}

.photos__more {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #FFFFFF;
  cursor: pointer;
}

@media (max-width: 880px) {
  .photos__block {
    gap: 8px;
  }

  .photos__wrap {
    width: calc((100% - 3 * 8px) / 4);
    border-radius: 10px;
  }
}


@media (max-width: 660px) {
  .photos__title {
    padding: 20px 0;
  }
}

@media (max-width: 420px) {
  .photos__title {
    flex-direction: column;
    gap: 18px;
    padding-bottom: 10px;
  }

  .photos__count {
    padding: 10px 20px;
  }

  .photos__block {
    flex-wrap: wrap;
  }

  .photos__wrap {
    width: calc((100% - 8px) / 2);
  }
}