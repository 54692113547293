.executive {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.executive__title {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.executive__count {
  min-width: 234px;
  padding: 14px 20px;
  font-weight: 400;
  border-radius: 10px;
  backdrop-filter: blur(20px);
}

.executive__count--error {
  background: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #FF0000;
}

.executive__count--success {
  background: rgba(0, 227, 0, 0.05);
  border: 2px solid rgba(0, 227, 0, 0.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  color: #00E300;
}

.executive__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.executive__item {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.executive__parameter {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.executive__parameter span {
  color: #000000;
}

@media (max-width: 880px) {
  .executive__list{
    gap: 16px;
  }
  .executive__item{
    padding: 20px;
    gap: 8px;
    border-radius: 10px;
  }
}

@media (max-width: 660px) {
  .executive__title {
    padding: 20px 0;
  }

  .executive__count {
    min-width: unset;
  }
}

@media (max-width: 420px) {
  .executive__title{
    flex-direction: column;
    gap: 18px;
    padding-bottom: 10px;
  }

  .executive__count{
    padding: 10px 20px;
  }
}
