.check-auto__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.check-auto__wrap--favorites {
    flex-direction: column-reverse;
}

.check-auto__tab-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.select-code-type {
  margin: 0;
  padding: 8px 2px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #000000;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.select-code-type--active {
  font-weight: 600;
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
}

.check-auto__input-wrap {
  width: 100%;
  display: flex;
  gap: 20px;
}

.check-auto__input {
  width: 100%;
  padding: 16px 30px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.check-auto__input:focus {
  outline: none;
  border: 1px solid var(--main-color);
}

.check-auto__input::placeholder {
  opacity: 0.5;
}

.check-auto__button {
  width: 155px;
  flex-shrink: 0;
  padding: 16px 20px; 
  font-weight: 600;  
  line-height: 120%;
  color: #FFFFFF;
  background: var(--main-color);
  border-radius: 10px;
}

.custom-helper-text {
  display: inline-block;
  margin-top: 5px;
  color: #FF0000;   
}

.custom-helper-text--favorites {
  position: absolute;
  margin-top: 0;
  top: -24px;
  left: 14px;
}
