.registration-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.registration-history__title {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registration-history__count {
  padding: 14px 20px;
  min-width: 239px;
  font-weight: 400;
  color: var(--main-color);
  background: rgba(0, 76, 219, 0.1);
  border: 2px solid rgba(0, 76, 219, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.registration-history__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 14px;
}

.registration-history__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 14px;
  width: 48%;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.registration-history__parameter {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.registration-history__parameter span {
  color: #000000;
}

@media (max-width: 880px) {
  .registration-history__item {
    padding: 20px;
    gap: 10px;
    border-radius: 10px;
  }
}

@media (max-width: 660px) {
  .registration-history__title {
    padding: 20px 0;
  }
  
  .registration-history__count {
    min-width: unset;
  }
}

@media (max-width: 560px) {
  .registration-history__list {
    flex-direction: column;
  }

  .registration-history__item {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .registration-history__title {
    flex-direction: column;
    gap: 18px;
    padding-bottom: 10px;
  }

  .registration-history__count {
    padding: 10px 20px;
  }
}