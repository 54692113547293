.tab__content-block--ads {
  gap: 18px;
}

.tab-ads__title {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tab-ads__content {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.tab-ads__images-wrap {
  display: flex;
  gap: 20px;
}

.tab-ads__photo {
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.tab-ads__subtitle {
  margin: 0;
}

.tab-ads__parameter {
  margin: 0;
  font-weight: 400;
}

.tab-ads__parameter span {
  font-weight: 300;
}

.tab-ads__summary {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.tab-ads__summary-item {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.tab-ads__summary-item span {
  color: #000000;
}

.tab-ads__button {
  padding: 12px 18px;
  width: 218px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: var(--main-color);
  background: #FFFFFF;
  border: 1px solid var(--main-color);
  border-radius: 8px;
}

@media (max-width: 1240px) {
  .tab__content-block--ads {
    gap: 15px;
  }

  .tab-ads__images-wrap {
    flex-wrap: wrap;
  }

  .tab-ads__summary {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 50px;
  }
}