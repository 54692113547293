.general-info {
  width: 100%;
}

.section-heading--general-info {
  margin: 25px;
}

.general-info__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 34px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.general-info__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.general-info__title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.general-info__heading {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.general-info__text {
  margin: 0;
}

@media (max-width: 880px) {
  .general-info__content {
    padding: 20px;
    gap: 20px;
    border-radius: 10px;
  }

  .general-info__block {
    gap: 6px;
  }
}