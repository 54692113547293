.modal-buy-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  isolation: isolate;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  z-index: 2;
}

.modal-buy-overlay--show {
  display: block;
}

.modal-buy {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  width: 1190px;
  gap: 30px;
  background-color: transparent;
  z-index: 3;
}

.modal-buy--open {
  display: flex;
  justify-content: center;
}

.modal-buy__left {
  width: 710px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 45px 40px 40px;
  isolation: isolate;
  background: #F8FAFF;
  border: 3px solid rgba(0, 76, 219, 0.1);
  box-shadow: 0px 4px 30px rgba(0, 76, 219, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  background-image: url('../../../media/images/new/modal-bg.png');
  background-repeat: no-repeat;
  background-size: 602px 391px;
  background-position: 110px 108px;
}

.modal-buy__left-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 106px;
}

.heading-three--modal-buy {
  width: 100%;
  text-align: center;
}

.heading-three--modal-buy span {
  color: var(--main-color);
}

.modal-buy__list {
  width: 260px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modal-buy__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 18px 15px 22px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3.36px 16.8px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(16.8px);
  border-radius: 15px;
}

.modal-buy__item-text {
  max-width: 151px;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.modal-buy__icon {
  width: 42px;
  height: 42px;
  background: rgba(0, 76, 219, 0.1);
  border: 1px solid rgba(0, 76, 219, 0.1);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

.modal-buy__icon--one {
  background-image: url("../../../media/images/new/modal-buy-icon1.svg");
}

.modal-buy__icon--two {
  background-image: url("../../../media/images/new/modal-buy-icon2.svg");
}

.modal-buy__icon--three {
  background-image: url("../../../media/images/new/modal-buy-icon3.svg");
}

.modal-buy__right {
  width: 450px;
  background: #FFFFFF;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 30px;
}

.modal-buy__right-wrap {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 20px;
}

.section-heading--modal-buy {
  font-weight: 600;
}

.modal-buy__one-ruble {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 15px;
  border: 1px solid var(--main-color);
  border-radius: 10px;
}

.one-ruble__one-report {
  position: relative;
  padding-left: 36px;
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);
}

.one-ruble__one-report:before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  background-image: url("../../../media/images/new/check-blue.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

.one-ruble__text {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
}

.modal-buy__pay-button {
  width: 100%;
  padding: 20px 40px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #FFFFFF;
  background: var(--main-color);
  border-radius: 10px;
}

.modal-buy__pay-button:disabled {
  opacity: 0.5;
}

.modal-buy__agreement {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modal-buy__agreement p {
  margin: 0;
}

.license-agreement-label,
.first-payment-label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
}

.license-agreement-label::before,
.first-payment-label::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-image: url("../../../media/images/new/icon-checkbox.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
}

.license-agreement-checkbox:checked+label::before,
.first-payment-checkbox:checked+label::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-image: url("../../../media/images/new/icon-checkbox-checked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
}

.protection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.protection__block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  background: #F8FAFF;
  border: 1px solid rgba(0, 76, 219, 0.1);
  border-radius: 10px;
}

.protection__text {
  margin: 0;
  font-weight: 400;
}

.protection__buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.protection__button {
  width: 60px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
}

.protection__button--mastercard {
  background-image: url("../../../media/images/new/icon-mastercard.svg");
  background-size: 44px 28px;
}

.protection__button--visa {
  background-image: url("../../../media/images/new/icon-visa.svg");
  background-size: 44px 28px;
}

.protection__button--mir {
  background-image: url("../../../media/images/new/icon-mir.svg");
  background-size: 32px 10px;
}

.modal-buy__close-button {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 24px;
  height: 24px;
  background-image: url('../../../media/images/new/close-button.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

@media (max-width: 1240px) {
  .modal-buy__left {
    width: 590px;
  }
}

@media (max-width: 1100px) {
  .modal-buy {
    gap: 20px;
  }

  .modal-buy__left {
    width: 485px;
    padding: 20px;
  }

  .modal-buy__right {
    width: 390px;
  }

  .modal-buy__right-wrap {
    padding: 25px 20px;
    gap: 14px;
  }
}

@media (max-width: 940px) {
  .modal-buy__left {
    width: 425px;
    border-radius: 20px;
  }

  .modal-buy__list {
    width: 240px;
  }

  .modal-buy__item {
    padding: 14px;
  }

  .modal-buy__item-text {
    font-size: 16px;
  }

  .modal-buy__icon {
    width: 36px;
    height: 36px;
    background-size: 20px 20px;
  }

  .modal-buy__right {
    border-radius: 20px;
  }

  .modal-buy__pay-button {
    padding: 16px 40px;
  }
}

@media (max-width: 880px) {
  .modal-buy__left {
    width: 354px;
  }
}

@media (max-width: 800px) {
  .modal-buy__left {
    display: none;
  }
}

@media (max-width: 480px) {
  .modal-buy__right {
    width: 320px;
  }
}