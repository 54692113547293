.ads-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ads-history__title {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ads-history__count {
  min-width: 260px;
  padding: 14px 20px;
  font-weight: 400;
  color: var(--main-color);
  background: rgba(0, 76, 219, 0.1);
  border: 2px solid rgba(0, 76, 219, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

.ads-history__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}

.ads-history__item {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.ads-history__parameter {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.ads-history__parameter span {
  color: #000000;
}

.ads-history__link {
  display: inline-block;
  word-break: break-all;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: var(--main-color)
}

@media (max-width: 880px) {
  .ads-history__item {
    padding: 20px;
    gap: 10px;
    border-radius: 10px;
  }
}

@media (max-width: 660px) {
  .ads-history__title {
    padding: 20px 0;
  }

  .ads-history__count {
    min-width: unset;
  }
}

@media (max-width: 420px) {
  .ads-history__title {
    flex-direction: column;
    gap: 18px;
    padding-bottom: 10px;
  }

  .ads-history__count {
    padding: 10px 20px;
  }
}
