.mailing-title {
    font-size: 32px!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important; 
    text-align: left;
    margin-bottom: 25px!important;
}

.mailing-submit-btn {
    background: #498EDF!important;
    height: 50px;
}