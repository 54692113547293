.tab-taxi__list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.tab-taxi__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 1240px) {
  .tab-taxi__list {
    gap: 20px;
  }
}

@media (max-width: 1100px) {
  .tab-taxi__list {
    gap: 14px;
  }

  .tab-taxi__item {
    gap: 6px;
  }
}