.user-balance-container {
    background: #e4e4e4;
    border-radius: 7px;
    margin-top: 25px!important;
}

.user-balance-title {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 28px!important;
    padding-top: 25px!important;
    padding-left: 25px;
}

.refill-balance-btn {
    background: #498EDF!important;
    height: 45px;
    color: white!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin-bottom: 25px!important;
    margin-top: 15px!important;
}