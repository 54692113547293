.balance {
  padding: 0px;
}

.balance__wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.your-balance {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 30px;
  gap: 20px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.your-balance__info {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.your-balance__buttons-wrap {
  display: flex;
  gap: 15px;
}

.your-balance__button {
  width: 209px;
  padding: 16px 26px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  border-radius: 10px;
  backdrop-filter: blur(20px);
}

.your-balance__button--сancel-subscription {
  color: #FF0000;
  background: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.05);
}

.your-balance__button--top-up-balance {
  color: #00E300;
  background: rgba(0, 227, 0, 0.05);
  border: 2px solid rgba(0, 227, 0, 0.05);
}

.section-heading--payment-history {
  margin: 0px 30px 30px;
}

.payment-history__wrap {
  display: flex;
  gap: 20px;
}

.payment-history__block {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 20px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.payment-history__title {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.payment-history__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;
  border-radius: 10px;
}

.payment-history__item--write-off {
  background: rgba(0, 76, 219, 0.1);
  border: 2px solid rgba(0, 76, 219, 0.1);
}

.payment-history__item--referral-bonuses {
  background: rgba(0, 227, 0, 0.05);
  border: 2px solid rgba(0, 227, 0, 0.05);
}

.payment-history__field {
  margin: 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.payment-history__field span {
  color: #000000;
}

.payment-history__empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.payment-history__empty-text {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 800px) {
  .your-balance {
    border-radius: 10px;
  }

  .payment-history__wrap {
    flex-direction: column;
  }

  .payment-history__block {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
  }
}

@media (max-width: 480px) {
  .your-balance {
    flex-direction: column;
  }
}