.tab-pledges__title-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab-pledges__list {
  height: 460px;
  overflow-y: auto;
}

.tab-pledges__list::-webkit-scrollbar {
  width: 7px;
  height: 7px;      
}

.tab-pledges__list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #E8EBF5;
  margin-top: 36px;
  margin-bottom: 32px;
}

.tab-pledges__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--main-color);
  cursor: default;
}

.tab__content-block--pledges {
  position: relative;
  padding-bottom: 0;
}

.tab__content-block--pledges::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 227px;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #FAFAFA 100%);
  pointer-events: none;
}

.tab-pledges__item {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}

.tab-pledges__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tab-pledges__date {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.tab-pledges__guarantors,
.tab-pledges__pledgees {
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
}

.tab-pledges__block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.tab-pledges__block  p,
.tab-pledgees__condition,
.tab-pledgees__number {
  margin: 0;
}