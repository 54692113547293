.tab-legal__list {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.tab-legal__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 1140px) {
  .tab-legal__list {
    gap: 16px;
  }
}
