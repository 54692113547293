.tab__content-block--calculation {
  gap: 30px;
}

.tab-calculation__title-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab-calculation__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 29px;
}

.tab-calculation__item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tab-calculation__date,
.tab-calculation__price,
.tab-calculation__details {
  margin: 0;
}

.tab-calculation__date {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.tab-calculation__price {
  color: rgba(0, 0, 0, 0.5);
}

.tab-calculation__price span {
  color: #000000;
}

.tab-calculation__details {
  font-weight: 400;
}