.short-report {
  position: relative;
}

.example {
  padding: 80px 0 60px;
}

.section-heading--example {
  margin-bottom: 40px;
  text-transform: uppercase;
  color: var(--main-color);
}

.example__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 90px;
}

.example__car-info {
  flex-shrink: 0;
}

.example__vin {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.example__vin span {
  color: rgba(0, 0, 0, 0.5);
}

.exmple__car-data {
  margin: 0;
  margin-bottom: 25px;
  font-weight: 400;
}

.example__img {
  border: 2px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
  border-radius: 20px;
}

.example__report {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}

.example__list {
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px;
  column-gap: 194px;
  row-gap: 18px;
}

.example__item {
  position: relative;
  padding-left: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.example__item-link {
  color: var(--main-color);
}

.example__item::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
  background-image: url("../../../media/images/new/icon-lock.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
}

.example__item--report:before {
  background-image: url("../../../media/images/new/icon-report.svg");
}

.example__buttons {
  width: 100%;
  display: flex;
  gap: 25px;
}

.example__button {
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  border-radius: 10px;
}

.example__button--report-example {
  width: 326px;
  padding: 12px 46px;
  color: var(--main-color);
  background: #FFFFFF;
  border: 1px solid var(--main-color);
}

.example__button--buy {
  width: 405px;
  padding: 25px;
  color: #FFFFFF;
  background: var(--main-color);
  box-shadow: 0px 4px 30px rgba(0, 76, 219, 0.4);
  backdrop-filter: blur(20px);
}

@media (max-width: 1240px) {
  .example__content {
    gap: 50px;
  }

  .example__list {
    column-gap: 84px;
  }

  .example__button--buy {
    width: 320px;
  }

  .example__button--report-example {
    width: 300px;
    padding: 12px 26px;
  } 
}

@media (max-width: 1100px) {
  .example {
    padding: 80px 0 20px;
  }

  .example__content {
    gap: 30px;
  }

  .example__img {
    width: 300px;
    height: auto;
  }

  .example__report {
    gap: 22px;
  }

  .example__list {
    column-gap: 56px;
    row-gap: 14px;
  }

  .example__button--report-example,
  .example__button--buy {
    width: 250px;
    padding: 16px 40px;
    font-size: 16px;
  }  
}

@media (max-width: 940px) {
  .example__list {
    column-gap: 24px;
  }

  .example__item {
    font-size: 16px;
  }

  .example__button--report-example,
  .example__button--buy {
    width: 234px;
  } 
}

@media (max-width: 940px) {
  .example__content {
    justify-content: flex-start;
  }

  .example__report {
    width: 100%;
  }

  .example__list {
    height: unset;
  }

  .example__buttons {
    flex-direction: column;
  }

  .example__button--report-example,
  .example__button--buy {
    width: 300px;
  }
}

@media (max-width: 800px) {
  .example__img {
    width: 230px;
  }
}

@media (max-width: 660px) {
  .example__buttons {
    width: 100%;
  }
  .example__button--report-example,
  .example__button--buy {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .example__content {
    flex-direction: column;
  }

  .example__car-info {
    width: 100%;
  }

  .example__img {
    width: 100%;
  }

  .example__list {
    padding-left: 20px;
  }
}
