.short-all-points {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.short-all-points__image-wrap {
  position: relative;
  width: 100%;
}

.short-all-points__image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: rgba(255, 255, 255, 0.3);
  border: 3px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 20px;
}

.short-all-points__button {
  width: 325px;
  padding: 25px 40px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 10px;
}

@media (max-width: 1100px) {
  .short-all-points {
    height: 100%;    
  }
  .short-all-points__image-wrap {
    height: 100%;
  }

  .short-all-points__image {
    height: 100%;
  }
}

@media (max-width: 420px) {
  .short-all-points__button  {
    width: 250px;
    padding: 20px 5px;
    font-size: 16px;
  }
}