.first-screen {
  width: 100%;
}

.promo-block {
  padding-top: 100px;
  padding-bottom: 60px;
}

.promo-block__container {
  width: 100%;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 70px;
}

.promo__heading {
  width: 507px;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 50px;
  line-height: 120%;
}

.promo__heading span {
  color: var(--main-color);
}

.promo__text {
  width: 459px;
  margin: 0;
  margin-bottom: 54px;
  font-size: 20px;
  line-height: 140%;
}

.promo-block__right {
  width: 600px;
}

.promo__car-block {
  width: 100%;
  position: relative;
  top: -20px;
  padding: 25px 30px;
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.promo__car-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.promo__car-info {
  width: 100%;
}

.promo__car-title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.promo__car-list {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 8px;
}

.promo__car-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo__car-check {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.promo__car-check--success {
  background-image: url("../../../media/images/new/success.svg");
}

.promo__car-check--error {
  background-image: url("../../../media/images/new/error.svg");
}

.promo__mercedes-picture {
  flex-shrink: 0;
}

.features {
  width: 100%;
}

.features__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 71px;
}

.features__item {
  position: relative;
  width: calc((100% - 3 * 71px / 4));
  padding-left: 48px;
}

.features__item::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

.features__item--one::before {
  background-image: url("../../../media/images/new/icon-bd.svg");
}

.features__item--two::before {
  background-image: url("../../../media/images/new/icon-elephant.svg");
}

.features__item--three::before {
  background-image: url("../../../media/images/new/icon-protection.svg");
}

.features__item--four::before {
  background-image: url("../../../media/images/new/icon-speed.svg");
}

.features__title {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}

.features__text {
  margin: 0;
}


@media (max-width: 1240px) {
  .promo-block__container {
    gap: 30px;
  }

  .promo__mercedes-picture {
    display: none;
  }

  .promo__car-list {
    column-gap: 20px;
  }

  .features__list {
    gap: 30px;
  }

  .features__item {
    width: calc((100% - 3 * 30px / 4));
    padding-left: 40px;
  }
}


@media (max-width: 1140px) {
  .promo-block {
    padding-top: 84px;
  }

  .features__title {
    margin-top: 0;
  }

  .features__list {
    gap: 20px;
  }

  .features__item {
    width: calc((100% - 3 * 20px / 4));
    padding-left: 34px;
  }

  .features__item::before {
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
  }
}

@media (max-width: 1100px) {
  .promo-block {
    padding-top: 60px;
    padding-bottom: 46px;
  }

  .promo-block__container {
    padding-bottom: 40px;
  }

  .promo__heading {
    width: 430px;
    font-size: 40px;
  }

  .promo__text {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .promo__car-block {
    padding: 20px;
  }
}

@media (max-width: 940px) {
  .promo-block__container {
    flex-direction: column;
  }

  .promo-block__left {
    width: 100%;
  }

  .promo__text {
    width: 100%;
  }

  .promo-block__right {
    width: 100%;
  }

  .promo-block__main-picture {
    display: flex;
    justify-content: center;
  }

  .promo__mercedes-picture {
    display: block;
  }

  .promo__mercedes {
    width: 150px;
  }

  .promo__car-wrap {
    gap: 50px;
  }

  .features__list {
    flex-wrap: wrap;
    gap: 35px;
  }

  .features__item {
    width: calc((100% - 35px) / 2);
    padding-left: 40px;
  }
}

@media (max-width: 800px) {
  .promo__heading {
    width: 400px;
    font-size: 36px;
  }
}

@media (max-width: 660px) {
  .promo__mercedes-picture {
    display: none;
  }
}

@media (max-width: 480px) {
  .promo__car-block {
    padding: 8px 12px 12px;
  }
}

@media (max-width: 480px) {
  .promo-block {
    padding-top: 46px;
  }

  .promo-block__container {
    padding-bottom: 30px;
  }

  .promo__heading {
    width: 100%;
    font-size: 30px;
  }

  .promo__car-list {
    height: unset;
  }

  .features__list {
    flex-direction: column;
    gap: 20px;
  }

  .features__item {
    width: 100%;
  }

  .features__title {
    margin-bottom: 4px;
  }
}