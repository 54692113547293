.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
}

.modal-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content img {
    max-width: 100%;
    max-height: 80vh; /* Adjust as needed */
}

.close-modal-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-content button {
    margin-top: 10px;
}

.close-modal-lightbox {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 28px!important;
    color: rgb(0, 0, 0)!important;
    background: #fff!important;
    border-radius: 50%;
    margin: 5px!important;
}