.check-auto__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.check-auto__input-wrap {
  width: 100%;
  display: flex;
  gap: 20px;
}

.check-auto__input {
  width: 100%;
  padding: 20px 30px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.check-auto__input--profile {
  padding: 18px 30px;
}

.check-auto__input:focus {
  outline: none;
  border: 1px solid var(--main-color);
}

.check-auto__input::placeholder {
  opacity: 0.5;
}

.check-auto__button {
  width: 155px;
  flex-shrink: 0;
  padding: 20px; 
  font-weight: 600;  
  line-height: 120%;
  color: #FFFFFF;
  background: var(--main-color);
  border-radius: 10px;
}

.check-auto__button--profile {
  width: 162px;
  padding: 18px 30px; 
}

.custom-helper-text {
  display: inline-block;
  margin-top: 5px;
  color: #FF0000;   
}

.check-auto__empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.check-auto__empty-text {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}