.tab__content-wrap--traffic-accident {
  gap: 20px;
}

.traffic-accident__title-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.traffic-accident__list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.traffic-accident__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.traffic-accident__date {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.traffic-accident__type {
  margin: 0;  
}

.traffic-accident__condition {
  margin: 0; 
}

.traffic-accident__button {
  width: 182px;
  padding: 10px 18px;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: var(--main-color);
  background: #FFFFFF;
  border: 1px solid var(--main-color);
  border-radius: 8px;
}