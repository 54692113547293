.report-list-container {
    position: relative;
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 25px!important;
    background: rgb(227,227,227);
    background: linear-gradient(90deg, rgba(227,227,227,1) 0%, rgba(73,142,223,0.702140231092437) 85%);
    cursor: pointer;
}

.report-list-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.report-favorite-btn {
    position: absolute;
    right: 10px;
    z-index: 10000;
}

.report-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: white;
}