.tab__content-block--mileage {
  gap: 15px;
}

.tab-mileage__text {
  margin: 0;
}

.tab-mileage__content-wrap {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.tab-mileage__info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tab-mileage__image-wrap {
  width: 100%;
}

.tab-mileage__image-wrap img {
  width: 100%;
}

.tab-mileage__text--bold {
  font-weight: 400;
}