.modal-payment__container {
    width: 450px;
    background: #fff;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    padding: 15px;
}

.payment-modal-close-icon {
    position: relative;
    left: 84px;
    bottom: 20px;
    color: white !important;
    cursor: pointer;
}

.modal-payment__header-form {
    background: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.payment-modal-title {
    font-family: 'Myriad Pro';
    font-weight: 700 !important;
    font-size: 24px !important;
    color: white !important;
}

.payment-modal-custom-amount {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-bottom: 1px solid black;
    padding-bottom: 25px;
}

.modal-payment__helper-text {
    font-family: 'Myriad Pro';
    font-weight: 400 !important;
    margin-bottom: 10px !important;
    text-align: center;
}

.modal-payment__input {
    width: 100%;
    padding: 14px 30px;
    font-weight: 300;
    font-size: 15px;
    line-height: 140%;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}

.modal-payment__input:focus {
    outline: none;
    border: 1px solid var(--main-color);
}

.modal-payment__custom-amount-btn {
    color: white !important;
    font-family: 'Myriad Pro';
    font-weight: 700 !important;
    width: 100%;
    height: 45px;
    margin-top: 10px !important;
    border-radius: 10px;
}

.modal-payment__amount-btn {
    color: white !important;
    font-family: 'Myriad Pro';
    font-weight: 700 !important;
    width: 95%;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
}

.payment-modal-amount-btn:hover {
    background: #277FE4 !important;
}

.payment-modal-btns-container {
    display: flex;
    text-align: center;
    margin-top: 15px !important;
}

@media (max-width: 480px) {
    .payment-modal-container {
        width: 350px;
        background: #E4E4E4 !important;
        padding: 15px;
        border-radius: 7px;
    }

    .payment-modal-close-icon {
        left: 330px;
    }

    .payment-modal-close-icon {
        position: relative;
        left: 60px;
        bottom: 20px;
        color: white !important;
    }
}

@media (max-width: 370px) {
    .payment-modal-container {
        width: 300px;
        background: #E4E4E4 !important;
        padding: 15px;
        border-radius: 7px;
    }

    .payment-modal-close-icon {
        left: 280px;
    }

    .payment-modal-close-icon {
        position: relative;
        left: 40px;
        bottom: 20px;
        color: white !important;
    }
}