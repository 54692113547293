.referral-card-container {
    background: #E4E4E4!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: 0 auto!important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
    user-select: none;
}

.referral-card-username {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 18px!important;
    margin-bottom: 15px!important;
}

.referral-card-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
}

.referral-card-price {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 32px!important;
    color: #6DDE45;
    margin-bottom: -10px!important;
    margin-top: 10px!important;
}

.referral-card-avatar {
    width: 70px;
    margin-top: 20px!important;
    border-radius: 50%;
}

.referral-card-date-badge {
    position: relative;
    width: 25px;
    right: 150px;
    bottom: 50px;
    padding: 3px;
    padding-top: 5px!important;
    padding-bottom: 5px!important;
    background: #498EDF!important;
    border-radius: 5px;
    z-index: 10;
    margin-top: -100px!important;
}

.referral-card-badge-text {
    writing-mode: vertical-rl;
    color: white;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}