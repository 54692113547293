
.review-comment-img {
    width: 200px;
}

.review-rating-container {
    display: flex;
    direction: row;
    margin: 0 auto!important;
}

.review-page-content {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 400!important;
    margin-left: 50px!important;
}

.like-dislike-review-img {
    width: 300px;
}

.rating-review-text {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
}

.review-user-name {
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    font-size: 22px!important;
}

.review-user-avatar {
    width: 200px;
}

.review-page-header {
    position: relative;
    padding-bottom: 60px;
}

.review-page-header::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10px; /* Высота тени */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}

.review-pagination-button {
    background: #498EDF!important;
    font-family: 'Ysabeau Infant', sans-serif!important;
    font-weight: 700!important;
    margin: 5px!important;
    height: 45px;
    color: white!important;
}

.review-pagination-button:disabled {
    opacity: 0.7;
    background: #686868!important;
    cursor: not-allowed;
  }
  
@media (max-width: 650px) {
    .review-page-header {
        padding-bottom: 50px;
    }
    .review-comment-img {
        width: 150px;
    }
    .like-dislike-review-img {
        width: 220px;
    }
    .review-user-avatar {
        width: 150px;
    }
}

@media (max-width: 650px) {
    .review-comment-img {
        width: 100px;
    }
    .review-user-avatar {
        width: 100px;
    }
    .like-dislike-review-img {
        width: 180px;
    }
    .review-rating-container {
        flex-direction: column;
    }
}